import React from "react";

const Footer = (props) => {
  return (
    <>
      <h2>Update Your Information with UTLA</h2>
      <p>
      Particularly at the beginning of a school year when we are negotiating a union contract, it is important for UTLA to have up to date contact information for all of its members. If any of your information has changed,{" "}
        <a
          target="blank"
          href="https://www.utla.net/members/update-your-information"
        >
          please go here
        </a>{" "}
        and update it.
      </p>
      <div id="footerContainer">
      <p>
            Monroe UTLA updates are available at <a href="/">www.MonroeUTLA.com</a>. If anybody has any questions, please contact us.
        {props.updateId >= 80 && (
          <> Monroe UTLA Chair
            Lourdes Quevedo can be reached at 
            <a href="mailto:jmhs.utla@gmail.com">jmhs.utla@gmail.com</a>.
         </>
        )}
 </p>
        <p>
          Sincerely,
          <br />
          Glenn Sacks
          <br />
          UTLA Co-Chair
          <br />
          (818) 470-8587
        </p>
      </div>
    </>
  );
};

export default Footer;
