module.exports = {
id:116,
title: 'The Hidden Forces Behind Chang’s Expensive New Mailers',
date: '2024-01-21',
content: `<p>We will have an important Monroe UTLA meeting at lunch on Wednesday the
24th in F6. UTLA Area Representative Hannah Joravsky will be there to
speak with us, and we will have excellent catered food from Chef Rasas.</p>
<h2>Monroe Teachers to March in Labor Solidarity with Striking CSUN Professors Tuesday 4:00-5:00 PM</h2>
<p>CSUN professors begin their strike Monday–Monroe UTLA members will join
their picket line Tuesday, their mobilization day, from 4 to 5 PM. You can
meet us on site at Prairie &amp; Darby streets at CSUN or, if you prefer
to ride with us, let us know &amp; we can take you there &amp; back to
Monroe. For this purpose we will be deploying the legendary Ovando Party
Van (courtesy of Monroe teacher Edgar Ovando), which was so popular during
the strike last year.</p>
<h2>The Hidden Forces Behind Chang’s Expensive New Mailers</h2>
<p>Over the past week many of you who live in District 3 have received
multiple pro-Daniel Chang campaign mailers. They’re of good cardstock,
colorful and professional, and no doubt expensive.</p>
<p>They are sent from a purported organization called “Kids First”--an
organization with no web presence, no track record or background, and no
website to tell us who they are, what they believe in, who backs them and
supports them, etc.</p>
<p>What's happening is this: an outside organization is helping to bankroll
the Chang campaign, and because they're not directly connected to Chang,
they are able to remain outside many of the limits of campaign finance
laws.</p>
<p>This is similar to what we saw when Schmerelson ran for re-election in
2020–outside, charter-backed organizations spent a fortune on expensive
mailers trashing Schmerelson.</p>
<p>To be fair to the funders of these expensive new Chang mailers, at least
to this point they have all been positive, not the trash attack ads we saw
last time. How long that will last, of course, is questionable. I hope I'm
wrong, but my guess is it will not be very long until the long knives come
out.</p>
<h2>Who’s Behind These Expensive New Mailers?</h2>
<p>It took a little searching to figure out who these people are–they
clearly don't want the public to know–but it turns out they're exactly who
you think they'd be: big money pro-charter/anti-public school and
anti-teachers union interests.</p>
<p>So who are they?</p>
<h2>Bill Bloomfield–$250K in Past 19 Days</h2>
<p>It's no surprise that wealthy charter supporter<a href="https://capitalandmain.com/bill-bloomfield-the-rainmaker-1101">
  Bill Bloomfield</a> is the main force behind the new Chang mailers. In
the past 19 days<a href="https://ethics.lacity.org/pdf/disclosure/CEC57/CEC57_112250.pdf#view=Fit?BE9B432B-0D83-14F2-918CE4C7CD72156C">
  he has thrown in a quarter million dollars</a> (that we know of) to
defeat Scott Schmerelson.</p>
<p>To learn more about Bloomfield and the long line of anti-public
education, anti-teachers union candidates and causes he has backed, see
Joel Warner’s<a href="https://capitalandmain.com/bill-bloomfield-the-rainmaker-1101">
  Bill Bloomfield: Big Dollars for Unregulated Education</a>.</p>
<h2>Can Bill Bloomfield's Big Money Be Beaten?</h2>
<p>Of course he can be beaten–in fact, <em>he often loses</em>.</p>
<p>For example, in the<a href="https://capitalandmain.com/bill-bloomfield-the-rainmaker-1101">
  2014 and 2018 elections he spent over $10 million</a> backing venture
capital investor<a href="https://en.wikipedia.org/wiki/Marshall_Tuck">
  Marshall Tuck</a>, former President of the Green Dot charter school
network, for State Superintendent of Public Instruction in California. He
lost both times.</p>
<p>In 2018 he was beaten by union-backed candidate Tony Thurmond, despite<a
  href="https://en.wikipedia.org/wiki/Marshall_Tuck#2018_election_for_State_Superintendent">
  lavish contributions from numerous pro-charter billionaires</a>,
including Eli Broad, various members of the Walton family/heirs to the
Walmart fortune, and others.</p>
<p>Warner notes that “efforts backed by Bloomfield over the years haven’t
proven especially successful” and kindly provides a list of some of the
losing causes Bloomfield has thrown in tens of millions of dollars to
support.</p>
<p>The takeaway? These people can be beaten, but we have to get out there
and do the work.</p>
<h2>Other Backers: $100K from California Republican Party General Counsel
Ashlee Titus</h2>
<p>Last week attorney<a href="https://www.c-span.org/person/?118962/AshleeTitus">
  Ashlee Titus</a>, a General Counsel for the California Republican Party,
gave<a href="https://ethics.lacity.org/pdf/disclosure/CEC57/CEC57_112250.pdf#view=Fit?BE9B432B-0D83-14F2-918CE4C7CD72156C">
  Kids First, Supporting Dan Chang for LAUSD School Board 2024 $98,332</a>,
most of it going to printing and postage.</p>
<p>She was recently<a href="https://www.rnla.org/rnla_honors_ashlee_titus_as_the_2023_recipient_of_the_betty_murphy_award">
  honored by the Republican National Lawyers Association</a>, whose
executive director Michael Thielen said she “helped us win the U.S.
House.”</p>
<p>She was a signatory on the RNLA’s October 2018 letter<a href="https://www.rnla.org/rnla_women_leaders_sign_letter_in_support_of_judge_kavanaugh">
  advocating the swift confirmation of embattled Trump Supreme Court
  nominee Brett Kavanaugh</a>.</p>
<h2>Titus &amp; the Federalist Society</h2>
<p>Titus is president of the Sacramento branch of the Federalist Society, a
conservative legal organization with an enormous influence in Republican
circles. Most of the Supreme Court's conservative majority that struck
down <em>Roe v Wade</em> in 2022 are or were members of the Federalist
Society, including Clarence Thomas, Brett Kavanaugh, Neil Gorsuch, Samuel
Alito, and Amy Coney Barrett.</p>
<p>The Federalist Society is a big supporter of<a href="https://fedsoc.org/commentary/fedsoc-blog/religious-charter-schools-another-brick-in-the-wall-of-separation">
  religious charter schools</a>, and played a large role in both the Trump
and the George W Bush administrations.</p>
<p>They also were involved in <em>Citizens United v. FEC. </em>The US
Supreme Court's controversial 2010 ruling in that case greatly weakened
campaign finance laws, paving the way for an increased influence of big
money in politics. </p>
<h2>$200K from Charter Public Schools PAC to Help Chang</h2>
<p>On January 12 the<a href="https://ethics.lacity.org/pdf/disclosure/CEC57/CEC57_112250.pdf#view=Fit?BE9B432B-0D83-14F2-918CE4C7CD72156C">
  Charter Public Schools PAC gave $200K</a> to Kids First, Supporting Dan
Chang for LAUSD School Board 2024.</p>
<h2>Why Do They Hide?</h2>
<p>Chang’s big money supporters prefer to remain in obscurity because they
know that most people support public schools and support teachers. They
want the public to think this election is about electing a “Teacher for
Change” for the school board but it isn't. It's about the charter industry
attempting to push out a prominent and effective opponent–Scott
Schmerelson–and sub in one of their own.</p>
<h2>Why Support Scott Schmerelson?</h2>
<p>To learn more about why we're supporting Scott Schmerelson, please see:</p>
<ul>
<li>UTLA's<a href="https://mcusercontent.com/e51f39a03d845e2cafae71eff/files/5e4b6be7-670a-8f64-9707-00704801e560/WHY_WE_RE_WALKINGFINAL2.pdf">
    WHY WE’RE WALKING</a></li>
<li>Valley West Area Chair Wendy Lozano’s<a href="https://utla.net/why-im-walking-for-scott-schmerelson/">
    WHY I’M WALKING FOR SCOTT SCHMERELSON</a></li>
<li><a href="https://monroeutla.com/update/114">The LA School Board Race
    &amp; Monroe Educators (Part 1)</a> (01/13/2024) </li>
</ul>
<h2>Monroe Teachers Will be Precinct Walking–Here's How to Sign Up</h2>
<p>This week your Chapter Action Team leaders will be asking you to sign up
for precinct walking. We’re focusing on Sunday, February 4th, the week
mail-in ballots go out, and Sunday, March 3rd, two days before the March
5th primary election. We will supply you with all needed materials plus
snacks and water–what we're looking for from you is a four-hour commitment
(11 AM - 3 PM). </p>
<p>If neither of these two days is convenient, members will also be doing
precinct walking Saturday February 17th, from 10 AM to 2 PM.</p>
<h2>FAQs Re: Precinct Walking</h2>
<p>Below are answers to some of the questions you've been asking concerning
precinct walking:</p>
<dl class="faq">
<dt>Q: Where do we go and how do we get materials?</dt>
<dd>A: We will all meet at the central UTLA location, 16340 Roscoe Blvd.
  in Van Nuys, where UTLA will provide us with all the materials we need,
  as well as snacks and water.</dd>
<dt>Q: How will we know what to say to people when we meet them?</dt>
<dd>A: UTLA will provide us with a script that you can use to whatever
  degree you want to.</dd>
<dt>Q: I don't know a lot about this campaign, how can I convince voters?</dt>
<dd>A: UTLA will provide talking points for you to use if you wish.</dd>
<dt>Q: What kinds of people will we be talking to?</dt>
<dd>A: You will not be knocking on just any doors–you'll be knocking on
  the doors of people who have voted in recent elections and who are
  generally sympathetic to public education. Much of what we will be doing
  is not convincing people who to vote for but instead to get out the
  vote. We will be reminding them to fill out their mail-in ballots or to
  make sure to go to the polls to vote on Tuesday, March 5th.</dd>
<dt>Q: Who will be walking with you?</dt>
<dd>A: You can walk with colleague(s) of your choice if you'd like, or you
  can go in the groups UTLA organizers will be forming.</dd>
<dt>Q: Will precinct walking interfere with the Super Bowl?</dt>
<dd>A: No, the Super Bowl is on Sunday, February 11th.</dd>
</dl>
<h2>Lourdes Quevedo Wins Seat in Valley West Board of Directors</h2>
<p>Congratulations to Lourdes Quevedo, who just won a seat on the NEA Valley
West Board of Directors.</p>
<p>When I walked into the Valley West Area Meeting Wednesday afternoon I had
to rub my eyes to make sure I wasn't seeing things–15 Monroe teachers
showed up there just to vote for Lourdes. For years I went to Valley West
Area Meetings alone–to see that many Monroe educators show up was surreal,
and a tremendous tribute to Lourdes’ leadership.</p>`
}