import React, {useState, useContext} from 'react'

const ViewAllButtonContext = React.createContext()

export function useViewAll() {
    return useContext(ViewAllButtonContext)
}

export function ViewAllButtonProvider({children}) {

    const [displayAll,
        setDisplayAll] = useState(false);

    const toggleViewAllButton = () => {
        setDisplayAll(prevDisplayAll => !prevDisplayAll)
    }

    return (
        <ViewAllButtonContext.Provider
            value={{
            displayAll,
            toggleViewAllButton
        }}>

            {children}
        </ViewAllButtonContext.Provider>
    )
}