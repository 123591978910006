module.exports = {
id:117,
title: 'The Charter/Chang Offensive Against Public Education',
date: '2024-01-28',
content: `<p>Thanks to all of you who came to our Monroe UTLA meeting on Wednesday
1/24 and signed up to do precinct walking for Scott Schmerelson.</p>
<p>We're still looking for more volunteers and are focusing on Sunday,
February 4th, the week mail-in ballots go out, and Sunday, March 3rd, two
days before the March 5th primary election–to learn more, see <a href="https://monroeutla.com/precinct-walking">
  Monroe Teachers Will be Precinct Walking</a>. </p>
<p>To sign up, contact your Chapter Action Team leader or reply to this
email and let me know which date you want to walk.</p>
<h2>A Tale of 2 Campaign Mailers–Schmerelson’s &amp; Chang’s</h2>
<p>Like everybody who lives in Los Angeles School Board District 3, I've
been receiving campaign mailers in favor of the LA School Board
candidates, mostly from the multi-millionaire charter backers funding
Chang, but also from organizations backing Scott Schmerelson.</p>
<p>There's a striking difference between the two–the mailers supporting
Schmerelson tell you <em>exactly who paid for it and what type of
  organizations they are</em>. Chang's, <a href="https://monroeutla.com/update/116">as I detailed in last week's Monroe UTLA Update</a>, are of a mysterious
and deliberately obscured origin.</p>
<p>The top funders of the group putting out the pro-Schmerelson mailer are
United Teachers Los Angeles, California Teachers Association, and the
American Federation of Teachers.</p>
<p>The pro-Chang mailer says it is funded by “Kids First”--an organization
with no web presence, no track record or background, and no website to
tell us who they are, what they believe in, and who backs them and
supports them. It took a little searching to figure out who these people
are–to learn more, see <a href="https://monroeutla.com/update/116">The Hidden Forces Behind Chang’s Expensive New Mailers</a> UPDATE #116 ·
01/21/2024.</p>
<h2>A Closer Look at Chang's Other Supporters</h2>
<p>Beyond the multi-millionaires and California Republican party bigwigs
stealthily funding Chang’s campaign, he is also drawing campaign
contributions from a wide variety of charter organizations including
charter networks, charter schools, charter management organizations, and
others. </p>
<p>Looking on the website <a href="https://ethics.lacity.org/data/campaigns/contributions/">
  https://ethics.lacity.org/data/campaigns/contributions/</a> reveals some
telling information. Chang’s backers include:<br>
</p>
<ul>
<li><a href="https://www.kipp.org/board-of-director/kathering-bradley-chair/">Katherine
    Brittain Bradley</a>, chairman of the board of the KIPP charter school
  network</li>
<li>The New Majority PAC, which counts the election of U.S. Senate
  Republican leader <a href="https://newmajority.com/victories/">Mitch
    McConnell as one of its “victories.”</a> Another of the “victories” it
  boasts of was defeating Measure EE, the UTLA-supported June 2019 ballot
  measure that would have increased funding for schools! (To learn more
  about Measure EE, see <a href="https://www.dailynews.com/2019/03/10/lausd-needs-proper-funding-the-parcel-tax-will-help/">LAUSD needs proper funding. The parcel tax will help</a><em>, Los
    Angeles Daily News, </em>3/10/19).</li>
<li>Elizabeth Saiger, Operations Manager of<a href="https://www.ednovate.org/">
    Ednovate</a>, which describes itself as a “network of independent
  public charter schools that spans across Los Angeles and into Orange
  County.”</li>
<li>Gregory Mcginity, Executive Director of <a href="https://www.ccsaadvocates.org/">California Charter Schools Association Advocates</a>. Their website
  states that charter schools “are under attack in Sacramento. Special
  interests want to protect their privileges at the expense of our
  students and families.” And let’s be clear about those “special
  interests”--they mean us.</li>
<li>Yvonne Chan, an Education Consultant for <a href="https://www.myvaughncharter.com/about-us">Vaughn Next Century Learning Center</a>, a charter school which
  operates five contiguous campuses</li>
<li>Michael Davis, CEO of Purpose Built Schools, a Georgia based
  pro-charter organization</li>
<li>Adriana Abich, CEO of <a href="https://www.caminonuevo.org/">Camino
    Nuevo Charter Academy</a> in Los Angeles</li>
<li>Anita Landecker, Executive Director of<a href="https://www.linkedin.com/company/exed/">
    ExED</a>, the back office service provider for California charter
  schools</li>
<li>Venture capital investor Marshall Tuck, former President of Green Dot
  charter schools and two-time candidate for California Superintendent of
  Public Instruction.</li>
<li>Caprice Young of charter school operator<a href="https://www.navigatorschools.org/">
    Navigator Schools</a></li>
<li>Parker Hudnut of the charter school management organization <a href="https://www.icefps.org/apps/pages/index.jsp?uREC_ID=3342589&amp;type=d&amp;pREC_ID=1499669">
    Inner City Education Foundation</a></li>
<li>Amy Held, a “School Leader” at <a href="https://www.larchmontcharter.org/">Larchmont Charter School</a> in Culver City</li>
<li>Melissa Kaplan, Executive Director of the charter network <a href="https://cwclosangeles.org/">Citizens of the World Charter Schools Los Angeles</a></li>
<li>Elijah Sugay, Chief Business Officer of <a href="https://brightstarschools.org/">Bright Star Schools</a>, a charter school organization that operates
  nine schools across Los Angeles</li>
<li>Eric Barlow, manager at the charter operator <a href="https://www.stem-prep.org/">Stem Preparatory Schools</a></li>
<li>Ana Teresa Dahan, Managing Director of Great Public Schools Now, a
  group which, <a href="https://www.latimes.com/local/education/la-me-edu-gpsn-grants-for-new-lausd-school-20160929-snap-story.html">according to the <em>Los Angeles Times</em></a>, “spawned from a
  controversial plan for rapid charter-school growth…the organization was
  born out of a confidentially circulated plan, obtained by <em>The Times</em>,
  that envisioned pulling half the students from the nation’s
  second-largest school system into charter schools. Charters operate
  independently of L.A. Unified and their growth to date — with about 16%
  of district enrollment — is one important factor contributing to the
  district’s budget woes because education dollars follow the
  students…Critics of the original charter-growth plan said that it would
  bankrupt L.A. Unified and asserted that such an outcome was probably one
  of the goals.” <em>The Times</em> notes that this plan was “spearheaded
  by the Eli and Edythe Broad Foundation”, major anti-public school
  privatizers.</li>
</ul>
<h2>To Be Fair to Chang…</h2>
<p>…he does have a few campaign contributors who are helping our schools.
One contributor, Cassandra Horton, is the Executive Director of the <a href="https://www.picklesgroup.org/">Pickles Group</a>, which their website says provides “free peer-to-peer
support and resources for kids and teens impacted by their parent’s
cancer.” </p>
<p>Another, Maurice Lee Condon, is the Director Of Communications for <a href="https://hftforschools.org/about/our-story/">Harbor Freight Tools For Schools</a>. One of the tragedies of modern
education is the decline of vocational classes–this organization says it
seeks to “increase understanding, support and investment in skilled trades
education in U.S. public high schools.”</p>
<p>Former Monroe principal Chris Rosas used to wistfully recount some of the
vocational programs we once had, particularly an airline-mechanics program
we had with Van Nuys Airport, where our students repaired actual aircraft
and trained to become airline mechanics.<br>
</p>
<h2>Why Is UTLA So Opposed to Charter Schools?</h2>
<p>For those new teachers and new UTLA members, our reasons for opposing
charter schools and charter school expansion may seem nebulous. </p>
<p>To learn more about the negative impact charter schools have on public
education, please see <a href="https://utla.net/resources/co-location-prop-39/">this detailed description from UTLA</a> and Jeff Bryant’s <a href="https://progressive.org/public-schools-advocate/tale-two-federal-grants-for-public-education-bryant-240120/">A Tale of Two Federal Grants for Public Education</a>.</p>
<p>For a debunking of the endless “charter schools do better than regular
schools” hype and the concomitant unstated (or sometimes quite clearly
stated) belief that this is public school teachers’ and teachers unions’
fault, please see my column <a href="https://www.realclearpolitics.com/articles/2020/08/02/thomas_sowell_goes_to_bat_for_charter_schools_whiffs.html">Thomas Sowell Goes to Bat for Charter Schools, Whiffs</a> (<em>RealClearPolitics.com</em>,
8/2/20)</p>
<h2>Campaign Slogans</h2>
<p>As silly as it sounds, one minor issue for us in this election is
slogans. Chang certainly does have good campaign slogans–“CHANG for
change”, and “Dan Chang for School Board: A Teacher for a Change” </p>
<p>Also, slogans involving “teacher” are good–as we've discussed, voters who
don't know either of the candidates will usually vote for the teacher. </p>
<p>I don't know exactly how we counter these–“Make students’ lives less
burdensome, vote for Scott Schmerelson”? Probably not…<br>
</p>
<h2>A Note about District 5</h2>
<p>From time to time in previous school board elections UTLA has backed one
candidate and Service Employees International Union Local 99 has backed a
rival candidate. This squanders union time and money, and I have at times
written critically of it. With the increased unity of UTLA and SEIU as a
result of our 2023 solidarity strike, I had hoped that in the 2024 School
Board elections we could avoid this problem.</p>
<p>Well, it's happening again. In District 5 SEIU has endorsed <a href="https://www.gracielaortiz.com/">Graciela Ortiz</a> and we’ve endorsed <a href="https://karlagriegoforschoolboard.com/">Karla Griego</a>.</p>
<p>I asked UTLA president Cecily Myart-Cruz about this problem a few months
ago. She said that SEIU is conducting their candidate endorsement process
democratically, and because they are, this limits their ability to
coordinate endorsements with us.</p>
<p>Her explanation convinced me, I guess.</p>`
}