module.exports = {
id:123,
title: 'What We Learned at the UTLA Leadership Conference (Part 3)',
date: '2024-08-09',
content: `<p>The week of September 9th through 13th will be platform week at Monroe High School, when Monroe UTLA will have all-member meetings to collect your input on UTLA&rsquo;s bargaining platform for the upcoming negotiations. We will have meeting details in upcoming mailings. </p>

<p>Parts one and two of &ldquo;What We Learned at the UTLA Leadership Conference&rdquo;, as well as previous updates, <a href="https://monroeutla.com/">are available here</a>.</p>

<p>Below is more of what we learned.</p>
<h2>Carvalho's Assault on School Autonomy</h2>
<p>UTLA president Cecily Myart-Cruz says the district has been trying to &ldquo;put up roadblocks&rdquo; to avoid having to fulfill the obligations of the strong contract we won in the March 2023 strike.</p>

<p>UTLA Area Representative Hannah Joravsky detailed the myriad ways LAUSD superintendent Alberto Carvalho is trying to &ldquo;strip schools of autonomy,&rdquo; particularly in relation to budgets and hiring. For example, she explained that, among other issues, Carvalho has eliminated hiring committees.</p>

<h2>LAUSD Infringing on Community Schools&rsquo; Rights</h2>

<p>Leaving aside what is state-mandated, conventional LAUSD schools have 50% power over selecting Professional Development. However, the 2022-2025 <a href="https://utla.net/app/uploads/2023/12/FINAL-CBA-2022-2025-UTLA-Contract-1.pdf">Collective Bargaining Agreement</a> states &ldquo;the LSLC at each Community School shall have decision making purview over&hellip;All site-based professional development.&rdquo; Clearly, Community Schools&rsquo; Local School Leadership Councils have 100% control over PDs.</p>

<p>Community Schools are granted this kind of autonomy because they face distinct challenges that are best supported at the school site rather than via one-size-fits all, district-imposed PDs.</p>

<p>LAUSD is now attempting to strip all Community Schools of this contractual autonomy. The May 20, 2024 LAUSD Memorandum <em>School-Site Professional Development Priorities and Banked Time Tuesdays for Schools 2024-25 </em>states &ldquo;All schools will deliver the &lsquo;Data-Driven Decision Making - Aligning 2024 Data to the Instructional Priorities&rsquo; module on August 20, 2024.&rdquo; UTLA is aware of this and is currently considering how to respond to this violation of our contract.</p>


<p>This limitation on Community School control over PDs is being done regardless of whether the school is a &ldquo;priority school.&rdquo; Priority schools are some of the LAUSD&rsquo;s &ldquo;low-performing&rdquo; schools. Unlike Community Schools, priority schools are not part of the LAUSD-UTLA contract&ndash;they are Carvalho&rsquo;s creation.</p>

<p>In June, Monroe's LSLC agreed on a slate of professional development sessions, but now the district is trying to contravene this vote by imposing its own PD agenda. In other words, LAUSD is preventing Monroe LSLC from following through on what we approved. Since the June vote was unanimous, it appears Principal Mat Diamond and Monroe UTLA are on the same side on this one, though obviously district actions put Diamond in a difficult position.</p>

<p>We will have updates as this progresses.</p>

<h2>Carvalho&rsquo;s &lsquo;Priority Schools&rsquo;</h2>
<p>As of July 1, Monroe is a priority school. In <a href="https://edsource.org/2024/lausds-100-priority-schools-target-districts-highest-need-students/706760">LAUSD&rsquo;s 100 priority schools target district&rsquo;s highest-need students</a>, Mallika Seshardi of <em>EdSource </em>explains:</p>

<p>&ldquo;Shortly after Alberto Carvalho became superintendent of LAUSD two years ago, he created a 100-day plan and named the district&rsquo;s top 100 priority schools&hellip;[they] are some of the district&rsquo;s lowest-performing campuses in all measures, and&hellip;would serve as the focal point of various district initiatives, such as decisions on adding additional instructional days to help students recover from pandemic learning loss and the new policy precluding charter schools from sharing their campuses.&rdquo;</p>

<p>Considerations for priority school placement include:</p>
<ul>
<li>attendance rates</li>
<li>SBAC scores</li>
<li>A-G course completion</li>
<li>proportion of students who are English learners.</li>
</ul>
<p>Priority schools comprise roughly 10% of LAUSD&rsquo;s overall enrollment. Below is a comparison of priority schools&rsquo; performance with LAUSD's overall performance:</p>
<ul>
<li>For 2022-2023, schools on the priority list had a chronic absenteeism rate of 38%, compared to 33% for LAUSD as a whole.</li>
<li>At priority schools, 23% met or exceeded English standards and 16% met or exceeded math standards. For LAUSD as a whole, those numbers were 41% and 31% respectively.</li>
<li>High School graduation rates were 81% at priority schools and 90% for the district as a whole.</li>
</ul>
<h2>Why Is Monroe Given a &lsquo;Priority Schools&rsquo; Designation, Limiting Our Autonomy?</h2>
<p>The high schools on the 2023-2024 LAUSD priority list include, among others, Dorsey, Fremont, Jefferson, Manual Arts, and Panorama High Schools. What do these and the others on the priority list all have in common? Students who overwhelmingly come from low socioeconomic backgrounds.</p>

<p>Obviously our students' low test scores, and graduation and attendance rates are not because of the imagined failings of Monroe teachers or administrators, but instead because of our students&rsquo; challenging backgrounds.</p>

<p>UTLA&rsquo;s solution is to encourage community schools, so we can attempt to deal with the many problems our students face, as opposed to just focusing on raw indicators that don&rsquo;t properly adjust for the difficulties these schools face.</p>

<p>You may recall last year we did an extensive data collection to determine what Monroe&rsquo;s main priorities should be for its students and families, and how our staff can best serve them. As we move into our second year as a community school, our focus is to put these priorities into action, but we cannot do this if LAUSD is limiting our autonomy. All of this presents difficulties for Monroe&rsquo;s LSLC.</p>

<h2>Tying Education Funding to Enrollment Would Help Our Most Challenged Schools</h2>

<p>Part of the problem we face is that education funding is tied to attendance rather than to enrollment. Students of low socioeconomic status face many extra challenges, challenges that reduce attendance rates. When students are absent, their schools and districts lose funding for them, however, their costs remain almost the same, regardless of daily attendance.</p>

<p>Most states have adopted an enrollment-based or partially enrollment-based system, but America's two largest states, California and Texas, are among a half dozen that still apportion school funding by ADA. In recent years, legislators in both states have tried unsuccessfully to change this.</p>

<p>The Los Angeles Unified School District, where 76% of our students live in poverty, has been supportive of these legislative efforts. Kelly Gonez, a member of the LAUSD School Board, explains, &ldquo;We lose about $200 million annually because of the difference between enrollment and average daily attendance.&rdquo;</p>

<h2>Does Tying Funding to Enrollment Instead of Attendance 'Reward Schools Which Aren&rsquo;t Doing Their Job&rsquo;?</h2>

<p>Critics often prefer to ignore our schools&rsquo; extra challenges. American Enterprise Institute Education Policy Studies Director Frederick Hess says funding based on school enrollment &ldquo;rewards schools which aren&rsquo;t doing their job.&rdquo;</p>

<p>Teacher union critic Lance Izumi asserts that under the proposed funding system, &ldquo;poor-performing districts would have no incentive to address their deficiencies and poor performance.&rdquo; Jon Coupal, president of the Howard Jarvis Taxpayers Association, lectures that &ldquo;schools should reward success, not failure.&rdquo;</p>

<p>Leaving these critics' insulting view of us aside, research does not indicate that linking school funding to attendance leads to higher attendance, nor that uncoupling attendance from funding leads to lower attendance.</p>

<p>What is clear is that socioeconomic disadvantages are linked with attendance problems. Education policy analysts Carrie Hahnel and Christina Baumgardner, authors of the 2022 report <a href="https://edpolicyinca.org/sites/default/files/2022-03/r_hahnel-mar2022.pdf">Student Count Options for School Funding</a>, cite research indicating that &ldquo;Students from lower income backgrounds are more likely to be absent since they face individual, family-related, and community-specific barriers that increase the likelihood of missing school&hellip;these students more often experience adverse health conditions, transportation-related barriers, school transfers, and housing insecurity.&rdquo;</p>

<p>I would not dream of asserting that all our absences are tied to students&rsquo; socioeconomic disadvantages, but the link is quite visible.</p>

<p>With parents working and childcare often unaffordable, if grandma gets sick, a girl might be asked to stay home that week to take care of her toddler siblings. If the family has an economic crisis, a boy might be asked to cut lawns or do construction work with his father. If a family member has to go to court or weave his or her way through the healthcare system to get the medical care they need, sometimes the student goes with them to serve as a translator.</p>


<p>Many high school seniors work weeknights. Some families are so economically embattled that just having an able-bodied 17-year-old at school all day every day instead of working/contributing to the family is a luxury they struggle to afford.</p>

<p>One of my best students spent much of her high school years living in a church basement after her family was evicted from their apartment. Another talented student&rsquo;s family lost their place to live and spent over a year living in a garage.</p>

<p>Sometimes students are gone for a week or two at a time. Latino comedian George Lopez says that for Mexican immigrants, &ldquo;You never went on vacation, you went to Mexico when someone was dying. They wake you up in the middle of the night: 'My mom&rsquo;s dying, get in the car.&rsquo;&rdquo; These long absences set the student back, and it's frustrating, but what can teachers do?</p>

<p>Enrollment-based funding is certainly not going to solve all of the problems faced by the schools and districts that serve low-income students, but it would help. And the discussion of attendance (as well as test scores, graduation rates, or practically anything else) would be more productive if our critics took a break from moralizing and instead looked at the challenges that students and educators in these schools face.</p>

<p>For more information, see <a href="https://www.realcleareducation.com/articles/2024/04/02/helping_our_most_challenged_schools_1022460.html">Helping Our Most Challenged Schools: Tie Funding to Enrollment, not Attendance</a> (<em>RealClearEducation.com</em>, 4/2/24).</p>`
}