import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import MainNavBar from './MainNavBar';

const PrecinctWalking = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <><MainNavBar /><Container>
            <main>
                <article>
                    <h1>Monroe Teachers Will be Precinct Walking–Here's How to Sign Up</h1>
                    <p>This week your Chapter Action Team leaders will be asking you to sign up
                        for precinct walking. We’re focusing on Sunday, February 4th, the week
                        mail-in ballots go out, and Sunday, March 3rd, two days before the March
                        5th primary election. We will supply you with all needed materials plus
                        snacks and water–what we're looking for from you is a four-hour commitment
                        (11 AM - 3 PM). </p>
                    <p>If neither of these two days is convenient, members will also be doing
                        precinct walking Saturday February 17th, from 10 AM to 2 PM.</p>
                    <h2>FAQs Re: Precinct Walking</h2>
                    <p>Below are answers to some of the questions you've been asking concerning
                        precinct walking:</p>
                    <dl className="faq">
                        <dt>Q: Where do we go and how do we get materials?</dt>
                        <dd>A: We will all meet at the central UTLA location, 16340 Roscoe Blvd.
                            in Van Nuys, where UTLA will provide us with all the materials we need,
                            as well as snacks and water.</dd>
                        <dt>Q: How will we know what to say to people when we meet them?</dt>
                        <dd>A: UTLA will provide us with a script that you can use to whatever
                            degree you want to.</dd>
                        <dt>Q: I don't know a lot about this campaign, how can I convince voters?</dt>
                        <dd>A: UTLA will provide talking points for you to use if you wish.</dd>
                        <dt>Q: What kinds of people will we be talking to?</dt>
                        <dd>A: You will not be knocking on just any doors–you'll be knocking on
                            the doors of people who have voted in recent elections and who are
                            generally sympathetic to public education. Much of what we will be doing
                            is not convincing people who to vote for but instead to get out the
                            vote. We will be reminding them to fill out their mail-in ballots or to
                            make sure to go to the polls to vote on Tuesday, March 5th.</dd>
                        <dt>Q: Who will be walking with you?</dt>
                        <dd>A: You can walk with colleague(s) of your choice if you'd like, or you
                            can go in the groups UTLA organizers will be forming.</dd>
                        <dt>Q: Will precinct walking interfere with the Super Bowl?</dt>
                        <dd>A: No, the Super Bowl is on Sunday, February 11th.</dd>
                    </dl>




                    <div>


                    </div>

                </article>
            </main>
        </Container></>
    )

}

export default PrecinctWalking