module.exports = [

    { old: '20180807', new: '1' },
    { old: '20180813', new: '2' },
    { old: '20180819', new: '3' },
    { old: '20180826', new: '4' },
    { old: '20180903', new: '5' },
    { old: '20180916', new: '6' },
    { old: '20180923', new: '7' },
    { old: '20180926', new: '8' },
    { old: '20181001', new: '9' },
    { old: '20181015', new: '10' },
    { old: '20181104', new: '11' },
    { old: '20181125', new: '12' },
    { old: '20181209', new: '13' },
    { old: '20181214', new: '14' },
    { old: '20181217', new: '15' },
    { old: '20181219', new: '16' },
    { old: '20181221', new: '17' },
    { old: '20181227', new: '18' },
    { old: '20190102', new: '19' },
    { old: '20190103', new: '20' },
    { old: '20190104', new: '21' },
    { old: '20190106', new: '22' },
    { old: '20190112', new: '23' },
    { old: '20190113', new: '24' },
    { old: '20190114', new: '25' },
    { old: '20190115', new: '26' },
    { old: '20190118', new: '27' },
    { old: '20190119', new: '28' },
    { old: '20190120', new: '29' },
    { old: '20190121', new: '30' },
    { old: '20190123', new: '31' },
    { old: '20190129', new: '32' },
    { old: '20190210', new: '33' },
    { old: '20190407', new: '34' },
    { old: '20190512', new: '35' },
    { old: '20190601', new: '36' },
    { old: '20190606', new: '37' },
    { old: '20190621', new: '41' },
    { old: '20190712', new: '42' },
    { old: '20190728', new: '43' },
    { old: '20190801', new: '44' },
    { old: '20190812', new: '45' },
    { old: '20190818', new: '46' },
    { old: '20190826', new: '47' },
    { old: '20190902', new: '48' },
    { old: '20191103', new: '50' },
    { old: '20191215', new: '52' },
    { old: '20200217', new: '53' },
    { old: '20200223', new: '54' },
    { old: '20200301', new: '55' },
    { old: '20200501', new: '56' },
    { old: '20200518', new: '57' },
    { old: '20200520', new: '58' },
    { old: '20200614', new: '59' },
    { old: '20200718', new: '60' },
    { old: '20200807', new: '61' },
    { old: '20200808', new: '62' },


]