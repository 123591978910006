module.exports = {
id:124,
title: 'What We Need You to Do for the Upcoming School Board Election',
date: '2024-09-02',
content: `<p>We need to help <a href="https://boardmemberscott.org/">Scott Schmerelson</a>, a longtime friend and ally of teachers and teachers unions, win LAUSD School Board District 3. As we did before for the March 3rd primary election, we will be precinct walking in District 3, which is to the north, south and west of Monroe. (Monroe itself is in District 6).</p>
<h2>Sign-Up for Precinct Walking</h2>
<p>We will be walking two weekends&ndash;the weekend of October 5 &amp; 6 and of October 19 &amp; 20. Monroe UTLA has selected these weekends because they bracket October 7th, which is when <a href="https://www.sos.ca.gov/elections/upcoming-elections/general-election-nov-5-2024">California counties will begin</a> mailing out vote-by-mail ballots. All California active registered voters will receive a vote-by-mail ballot. Ballot drop-off locations open on October 8, 2024.</p>
<p>We will supply you with all needed materials to precinct walk, plus snacks and water&ndash;what we're looking for from you is a four-hour commitment. To sign up, simply reply to this email and tell us which date(s) you'd like to walk.</p>
<p>In March, the precinct walking was well-organized, and UTLA also fed us <em>very</em> well.</p>
<h2>Why Scott Schmerelson?</h2>
<p>To learn more about why we need to support Schmerelson, please see these Monroe UTLA updates sent out during the primary election campaign:</p>
<ul>
<li><a href="/update/117">The Charter/Chang Offensive Against Public Education<br /></a>UPDATE #117 &middot; 01/28/2024</li>
<li><a href="/update/116">The Hidden Forces Behind Chang&rsquo;s Expensive New Mailers<br /></a>UPDATE #116 &middot; 01/21/2024</li>
<li><a href="/update/115">The LA School Board Race &amp; Monroe Educators (Part 2)<br /></a>UPDATE #115 &middot; 01/15/2024</li>
<li><a href="/update/114">The LA School Board Race &amp; Monroe Educators (Part 1)<br /></a>UPDATE #114 &middot; 01/13/2024</li>
</ul>
<p>We will have much more on this in upcoming updates.</p>
<h2>Why We Need You to Volunteer, Reason #1&ndash;We Need to Counter the Big Money Contributors with Our Boots on the Ground</h2>
<p>So far this year, Schmerelson has raised $239,600, largely from people like you and I. Chang has raised <em>10 times that amount</em>&ndash;$2,354,000, largely from wealthy charter supporter <a href="https://capitalandmain.com/bill-bloomfield-the-rainmaker-1101">Bill Bloomfield</a> and the California Charter Schools Association Advocates. High-dollar special interests are trying to buy this election</p>
<p>You can see this on the <a href="https://ethics.lacity.gov/ss/314355">City of Los Angeles Ethics Commission</a> website, or perhaps a little clearer on my Google spreadsheet at <a href="https://docs.google.com/spreadsheets/d/1_FH8DIMjMzx05PyoC1J9ajzeqzavE2gJPWPbTySf8tY/edit?usp=sharing">Schmerelson v Chang Fundraising</a>.</p>
<h2>Why We Need You to Volunteer, Reason #2&ndash;Polling, Research Clearly Show That Voters Listen to Teachers</h2>
<p>Over the past several decades, public school teachers have been endlessly attacked by critics who blame teachers for students&rsquo; low test scores, as opposed to the living conditions endured by the large black/Latino/immigrant underclass that teachers work hard to serve. Nonetheless, polls show that the average voter respects and sympathizes with teachers, particularly in relation to education matters. The tremendous public support United Teachers Los Angeles enjoyed during our 2019 and 2023 strikes is more evidence of this.</p>
<p>When voters meet a teacher motivated enough to be walking door-to-door on a Saturday or a Sunday, they listen. If a teacher asks them to vote for Schmerelson, most will. This is our true strength, something Chang and the wealthy special interests funding him cannot replicate.</p>
<h2>FAQs Re: Precinct Walking</h2>

<p>Below are answers to some of the questions you've asked concerning precinct walking:</p>

 <dl class="faq">
                        <dt>Q: Where do we go and how do we get materials?</dt>
                        <dd>A: We will all meet at the central UTLA location in Van Nuys, where UTLA will provide us with all the materials we need, as well as snacks and water.</dd>
                        <dt>Q: How will we know what to say to people when we meet them?</dt>
                        <dd>A: UTLA will provide us with a script that you can use to whatever degree you want to.</dd>
                        <dt>Q: I don't know a lot about this campaign, how can I convince voters?</dt>
                        <dd>A: UTLA will provide talking points for you to use if you wish.</dd>

                        <dt>Q: What kinds of people will we be talking to?</dt>
                        <dd>A: You will not be knocking on just any doors&ndash;you'll be knocking on the doors of people who have voted in recent elections and who are generally sympathetic to public education. We will be reminding them to fill out their mail-in ballots or to make sure to go to the polls to vote on Tuesday, November 5th.</dd>

                         <dt>Q: Who will be walking with you?</dt>
                        <dd>A: You can walk with colleague(s) of your choice if you'd like, or you can go in the groups UTLA organizers will be forming.</dd>

                         
                       
                    </dl>




<h2>Important Election-Related Dates for Monroe UTLA Members</h2>
<ul>
<li>October 5 &amp; 6&ndash;First set of Monroe precinct walks</li>
<li>October 7th&ndash;<a href="https://www.sos.ca.gov/elections/upcoming-elections/general-election-nov-5-2024">California counties will begin</a> mailing out vote-by-mail ballots.</li>
<li>October 8th&ndash;Vote-by-mail ballot drop-off locations open&nbsp;</li>
<li>October 19 &amp; 20&ndash;Second set of Monroe precinct walks.&nbsp;</li>
<li>October 29&ndash;California Secretary of State's recommended last day to turn in vote-by-mail ballots.</li>
<li>November 5&ndash;General Election</li>
</ul>
<h2>Update on LAUSD&rsquo;s Out-of-Classroom Personnel Work Hour Extension Demand</h2>
<p>As <a href="/update/118">we discussed</a> in these Monroe UTLA updates during the spring semester, the district has been attempting to extend the on-site obligations of out-of-classroom personnel. UTLA Elementary Vice-President<a href="https://utla.net/contacts/maria-miranda-2/"> Maria Miranda</a> explains that the district has attempted to use some inconsistent and unclear language in the contract to &ldquo;pull a fast one&rdquo; on roughly 2,000 UTLA bargaining unit members.</p>
<p>LAUSD attempted to ram this through instead of openly negotiating it with UTLA, but UTLA pushed back and we are now in negotiations over a sideletter on this issue. Part of the problem has been that out of classroom personnel have been dealt with unevenly throughout the district, depending on the school site. One thing we expect to come out of these negotiations is uniformity. But the issues currently faced by UTLA out of classroom personnel are complicated.</p>
<p>I attended the latest out of the classroom assignment meeting last Tuesday, updating us on UTLA's bargaining over the issue with the district. LAUSD does not appear to be very interested in resolving this issue, and Miranda, who has been the leader on this issue, told us that UTLA is filing a grievance against the district.</p>
<p>The next step is the mandatory settlement conference, but UTLA Grievance Coordinator Carl Joseph thinks it's going to be expedited to get the dispute to arbitration. And then, of course, beginning in February, all of this has to be negotiated for our upcoming contract. Miranda says that now that a grievance has been filed, there's not much UTLA members can do except wait.</p>
<p>A Win for Teachers Who Face Allegations</p>
<p>Time and again we&rsquo;ve seen educators yanked out of their schools over allegations, and the teachers must wait many months before they are even told what the allegation against them is. In the past these teachers were sent to &ldquo;teacher jail&rdquo; at Beaudry, but several years ago UTLA got LAUSD to allow them to be at home instead. These educators are no longer called &ldquo;housed&rdquo;, they're called &ldquo;reassigned.&rdquo; Either way, it is an agonizing situation for them.</p>
<p>At Wednesday's meeting, UTLA Secondary Vice President Alex Orozco told us that UTLA Treasurer Gloria Martinez and UTLA Grievance Coordinator Carl Joseph took LAUSD to court and got an agreement from the district that the accused educator must receive a written description of the allegations against them within five days of being removed from a campus, and that this description must include the school year during which the alleged incident occurred.&nbsp;</p>
<p>I'm guessing LAUSD will try to keep the descriptions as vague as possible, and that the next battle will be over getting them to include sufficient detail. Still, it's definitely a step forward.</p>
<h2>District on the Warpath Against Teacher Absenteeism</h2>
<p>At the Valley West Area meeting last Wednesday, UTLA Secondary Vice President Alex Orozco discussed LAUSD&rsquo;s aggressive new effort to crack down on teacher absenteeism. If you are or become a target of this, please let us know.</p>
<p>Orozco noted that absenteeism is relatively high among LAUSD educators.</p>`
}