module.exports = {
id:112,
title: 'What We Learned at the UTLA Leadership Conference<br />(Part 2)',
date: '2023-08-09',
content: `<p>Along with Monroe UTLA Chapter Chair Lourdes Quevedo, Vice Chair Jacqueline Fonseca, and newly-elected Vice Chair Geronimo Mendoza, I attended the UTLA Leadership Conference in downtown Los Angeles this weekend. Below is some of what we learned, particularly about UTLA&rsquo;s goals for the 2023-2024 school year.</p>
<p><h2>New Provisions to Help SpEd Educators</h2></p>
<p>At the conference, Darlene Haezaert, the West Valley Area Representative for Special Education outlined some of the new contract&rsquo;s gains for SpEd educators.</p>
<p>For one, there are now fixed class size caps for SpEd classes. Nevertheless, she noted that &ldquo;administrators tend to throw contract violations on new teachers because they figure they're either too scared to protest or don't know their rights." If you have questions, Haezaert can be reached at darlene.haezaert@utla.net or (213) 368-6235.</p>
<p>Also, SpEd educators have three release days to conduct assessments. On these days you are not supposed to be asked to cover other classes, and you're supposed to have your full regular lunch.</p>
<p>Regarding the release days, Haezaert recommended that SpEd teachers plan them in advance rather than &ldquo;springing them on administrators with little notice.&rdquo;</p>
<p>At the conference she mentioned she had a special template for these requests&ndash;I asked her for it so I could share it with you and she was kind enough to send it to me. It can be<a href="https://docs.google.com/document/d/1zcfzGVaegqZdBRbWyxh8quW3hsp71F0whzqGWB2NlDU/edit?usp=sharing"> found here</a>.</p>
<p><h2>Monroe SpEd Educators Zoom Meeting with Haezaert</h2></p>
<p>Monroe SpEd Educators will have a Zoom meeting with Haezaert on Monday, August 21 at 5:30 PM.</p>
<p><h2>UTLA Priority: the Upcoming School Board Elections </h2></p>
<p>Another UTLA priority for 2023-2024 concerns the 2024 Los Angeles school board elections. These will be held on March 5, the same day as the California primary election, which means there will be a relatively large turn out.</p>
<p>Up for reelection are board districts 1,3,5, and 7. The situation in each district is as follows:</p>
<p>District 1&ndash;long time board member George McKenna, who has generally been pro-UTLA, is retiring. We&rsquo;re guessing the charter/anti-teachers union forces will be putting up a candidate and funding them lavishly, but we don't have any details yet.</p>
<p>District 3&ndash;Scott Schmerelson will be running for re-election, more on that below</p>
<p>District 5&ndash;Similar to District 1. Board Member Jackie Goldberg, also generally pro-UTLA, is retiring.</p>
<p>District 7&ndash;Tanya Ortiz Franklin is expected to run for re-election. She won her seat in 2020, was not endorsed by UTLA, and generally has not been supportive of us. She is seen as being pro-corporate charter.</p>
<p><h2>What We Will be Asking You to Do</h2></p>
<p>UTLA is emphasizing the strength of our grassroots, get out the vote efforts. A study conducted by the California Teachers Association found that nothing is more effective in a school board race than the teachers themselves knocking on doors.</p>
<p>Despite the endless hit jobs conservative media has done on teachers unions and on teachers themselves, we are still a respected force in society. When a teacher tells a voter who they support in the school board election, the study showed that the voter will usually vote that way.</p>
<p>Remember, of course, in these elections we are up against well-heeled opponents who can spend tremendous amounts of money on attack ads. In recent school board elections, UTLA was hamstrung to some degree because Covid made it difficult to do this grassroots campaigning.</p>
<p>UTLA is currently going through the process of vetting the candidates, communicating with them, and figuring out who, if anybody, to endorse in each race. In January and February we will be organizing our members to go door-to-door to support various candidates.</p>
<p><h2>More UTLA-SEIU Unity this Time</h2></p>
<p>From time to time in previous school board elections UTLA has backed one candidate and SEIU has backed a rival candidate. This squanders union time and money. With the increased unity of UTLA and SEIU as a result of our solidarity strike, it is believed that in the 2024 School Board elections we can avoid this problem. Let&rsquo;s hope that is the case.</p>
<p><h2>Scott Schmerelson in the Eye of the Storm</h2></p>
<p>In 2020, the charter lobby and its billionaire accomplices spent millions to capture the school board, including an overwhelming smear campaign against Schmerelson.</p>
<p>Speaking as a US Government teacher who has spent much time perusing campaign ads, both current and historical, it would be hard for me to find anything in the modern era as misleading and dishonest as these anti-Schmerelson ads. The ads portrayed him as greedy and evil because some investment he really had no connection to was somehow connected to vaping.</p>
<p>I had the pleasure of speaking with Schmerelson for a few minutes at the <a href="https://utla.net/about-us/pace-leadership/">Political Action Council of Educators</a> gathering Saturday evening. I live in Schmerelson&rsquo;s district and I told him I wasn't looking forward to once again having my mailbox filled with these colorful, expensive, and ridiculous attack ads. He laughed. It's nice to see that he's ready, willing and able to deal with such attacks again&ndash;I expect they'll be coming.</p>
<p>Schmerelson said there were five people running against him but nobody who stood out.</p>
<p>One is from the California Charter Schools Association, who are one of the biggest opponents of UTLA. Another is from the Granada Hills charter empire, who are also opponents of UTLA.</p>
<p>If the opposition is well organized &ndash; and I hope they aren&rsquo;t &ndash; one of those two candidates will drop out and support the other one. From the perspective of our opponents, it makes no sense for both to run, as it will split the anti-UTLA/anti-public education vote and perhaps throw the election to Schmerelson.</p>
<p><h2>Schmerelson&rsquo;s Key Role in Our 2019 Strike</h2></p>
<p>Two days into the 2019 strike Schmerelson became the first board member to break with then-LAUSD Superintendent Austin Beutner, releasing a <a href="https://achieve.lausd.net/Page/9117?rel=outbound">statement called &ldquo;Where Do I Stand?&rdquo;</a> Schmerelson said:</p>
<p>&ldquo;The repeated message to Board Members, over the last several months, was that the only way to avoid a strike was for the Board to speak with one voice&hellip;</p>
<p>&ldquo;I can no longer allow Mr. Beutner to speak for me or to suggest that the massive public relations, and often misinformation, campaign that he is waging represents my views about the current teachers strike. We need to end the strike&hellip;</p>
<p>&ldquo;My constituents, and parents throughout the District, are demanding to know just where I stand. I will tell you: As a retired LAUSD teacher, counselor, and principal, I dedicated my life and career serving LAUSD kids. I continue to stand with the kids. For me, this means that I Stand with Teachers because today they are standing for what&rsquo;s best for students.&hellip;</p>
<p><br />&ldquo;Instead of repeating the &lsquo;doom, gloom and heading for bankruptcy&rsquo; predictions that we have heard for decades, I believe that it is Mr. Beutner&rsquo;s job to honestly identify sources of funding buried in our existing budget, and the revenue growth predicted for next year, that could be creatively sourced and invested in the students who need smaller classes and adequate support services now.&rdquo;</p>
<p><h2>Other School Board Seats</h2></p>
<p>School board members in districts two, four, and six are not up for re-election.</p>
<p>In Board District 2, Rocio Rivas is seen as the most reliable pro-UTLA board member. She supported both the 2019 and 2023 strikes, and opposed a privatized charter school in Pico Rivera. She won her board seat, with UTLA backing, in 2022.</p>
<p><br />In Board District 4, Nick Melvoin&rsquo;s candidacy was bankrolled by the charter industry in 2017 in the most expensive school board election in American history. He was elected in 2017 and 2022 with support from the CCSA, billionaire Bill Bloomfield, and other anti-UTLA interests. He was Beutner's closest ally on the board and is Carvalho&rsquo;s closest ally today. He is apparently planning to run for Congress in 2026.</p>
<p>In Board District 6, Kelly Gonez was originally seen as being part of the corporate charter block, but has steadily moved towards UTLA. We endorsed her in 2022. She is pro-Community schools, in favor of the Black Student Achievement Plan, and she was the key vote against the student-centered funding scheme we successfully fought against two years ago.</p>
<p><h2>Who Receives These Updates?</h2></p>When an update only concerns information specific to Monroe UTLA, it goes only to Monroe UTLA members. When it only concerns information general to UTLA and LAUSD, it goes out both to Monroe members and to some of the other chapter chairs and vice-chairs in the San Fernando Valley. When it concerns information both related to Monroe and to UTLA/LAUSD in general, I send the full one out to Monroe members, and the non-Monroe information out to other San Fernando Valley chapter chairs and vice-chairs.</p>
<p>Chapter chairs and vice-chairs often exchange information that helps our members. In recent months Valley East Area Chair Scott Mandel has been particularly helpful in this manner.</p>`
}