module.exports = {
id:115,
title: 'The LA School Board Race & Monroe Educators (Part 2)',
date: '2024-01-15',
content: `<p>As we've mentioned, your chapter action team leaders will soon be
reaching out to you concerning precinct walking for the upcoming School
Board elections. <a href="https://ethics.lacity.org/elections/#S268">Pro-teacher
  candidates are being badly outspent by the charter lobby</a> and other
UTLA opponents–we need boots on the ground to counter them.<br>
</p>
<h2>Who Are the Other Candidates for School Board District 3?</h2>
<p>Besides UTLA-endorsed <a href="https://utla.net/why-im-walking-for-scott-schmerelson/">incumbent
  Scott Schmerelson</a> and charter school advocate <a href="https://www.chang4change.org/">Daniel
  Chang</a>, there are two other District 3 candidates, neither of whom is
expected to have much of an impact on the race:</p>
<ul>
<li><a href="https://www.lausd3.com/">Andreas Farmakalidis</a>, an owner
  of a music school, and former LAUSD teacher, who states, “I am an
  advocate for school choice, including charter schools and voucher
  programs.”</li>
<li><a href="https://www.raquelvillalta.com/">Raquel Villalta</a>, who,
  according to her website, taught kindergarten through 6th grade from
  2006 until LAUSD “discriminated against her sincerely held religious
  belief against the Covid shot when they removed her out of <em>(sic)</em>
  the classroom.”</li>
</ul>
<p>Carl Petersen, a parent advocate for public education, has provided the
public useful information on <a href="https://patch.com/california/northridge/lausd-candidate-andreas-farmakalidis-prop-39-co-locations">Farmakalidis</a>
and <a href="https://patch.com/california/northridge/fifth-challenger-joins-lausd-school-board-race-bd3">Villalta</a>.
While I'm sure that both <a href="https://patch.com/california/northridge/lausd-candidate-andreas-farmakalidis-special-education">Farmakalidis</a>
and <a href="https://patch.com/california/northridge/fifth-challenger-joins-lausd-school-board-race-bd3">Villalta</a>
are sincere and mean well in their own way, neither would be good for our
schools.</p>
<h2>Andreas Farmakalidis</h2>
<p>Beyond <a href="https://patch.com/california/northridge/lausd-candidate-andreas-farmakalidis-prop-39-co-locations">Farmakalidis</a>’
clear support for charters, he doesn't provide many specifics in his
public statements and on his website. For example, Petersen asked him if
he supports the "<a href="https://www.lausd.org/cms/lib/CA01000043/Centricity/Domain/1057/08.22.23RegBdOBpostRevised.pdf">Creating
  a Charter Schools Co-Location Policy to Mitigate Impacts Caused by
  Proposition 39</a>" authored by Board President Jackie Goldberg and Dr.
Rocio Rivas. He responded affirmatively, which is nice, but then seems to
backtrack as he tries to be all things to all people about the issue.</p>
<p>Petersen also asked him “Currently, charter schools have a past-due
balance of $3,708,006. As a Board Member would you revoke the charter of
any school that refused to pay these fees when a bill is presented?”<a href="https://patch.com/california/northridge/lausd-candidate-andreas-farmakalidis-prop-39-co-locations"><br>
</a></p>
<p><a href="https://patch.com/california/northridge/lausd-candidate-andreas-farmakalidis-prop-39-co-locations">Farmakalidis</a>
answered no, instead providing platitudes about the need for “open
dialogue and resolution”, “engagement”, etc.</p>
<p>Given <a href="https://patch.com/california/northridge/lausd-candidate-andreas-farmakalidis-prop-39-co-locations">Farmakalidis</a>'
pro-charter and pro-voucher position, whatever his other virtues might be,
he’s not what we're looking for in a school board member.</p>
<h2>Raquel Villalta</h2>
<p>Petersen explains that “what Villalta leaves out of her narrative
(referenced above) is that as a reasonable accommodation for her
‘religious beliefs,’ she was initially assigned to the Virtual Academy
where she did not have direct contact with students. It was only after
this site became ‘over-teachered’ that the District was unable to
reasonably accommodate her.”</p>
<p>He says:</p>
<p>“While Villalta has the right to reject the COVID vaccine on religious
grounds, this does not override the rights of children to attend school in
a safe environment. Anyone with a true ‘passion for student success’ would
understand that.”</p>
<p><a href="https://patch.com/california/northridge/fifth-challenger-joins-lausd-school-board-race-bd3">Villalta’s</a>
slogan is “defending our children”, by which she means she “will fight
indoctrination in education and oppose gender ideology currently in
LAUSD's curriculum as it undermines parents…”</p>
<p>What Villalta and other conservative Christian opponents of public
education fail to understand is that the overarching goal of many of the
LGBTQ policies they are criticizing is not “indoctrination” or some
ideological crusade, it's bullying prevention.</p>
<p>Hostility towards LGBTQ youth, particularly gay male youth, has always
been at the heart of bullying. In 1981 I graduated from John F. Kennedy
High School, three miles away from where I teach today, and I often share
with my students the differences between attitudes towards LGBTQ then and
now. Bullying is much less common now, in part because of school
districts' exhaustive efforts to combat it.</p>
<p>Granted that it’s guilt by association, Petersen also notes that her
Instagram account is followed by what he describes as the “<a href="https://medium.com/discourse/and-the-lesson-today-is-how-to-hate-b78674052f2b?sk=67fc0a12ae1b29a6eefeba035e29e4c7">anti-LGBTQ+
  bigots at the Saticoy Elementary “Parents” group</a>.”</p>
<p>One of the favorite bugaboos of the forces Villalta apparently i aligned with concerns
<a href="https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/383/BUL-6224.2%20Transgender%20Policy%205%2013%2019.pdf">LAUSD’s
  instruction</a> that school personnel "shall take into consideration the
safety, health and well-being of the student in deciding whether to
disclose the student’s gender identity or expression to parents."</p>
<p>The widespread criticism of this has little validity–educators aren’t
keeping students’ gender identities “secret”—we’re respecting our
students’ needs and privacy. By contrast, these critics are asking us to
violate our students’ trust. At Monroe High school, and in much of LAUSD,
the student body is heavily Latino and immigrant, and students' families
tend to be socially conservative and less accepting of LGBTQ, although
this is changing. I've seen many instances of this lack of acceptance, and
I'm guessing most other teachers have also.</p>
<p>For example, during the COVID school closures, one of my students was
kicked out of her parents’ house, largely over her sexual orientation. She
struggled to finish her senior year and graduate, while working long hours
to pay her rent.</p><p>Another LGBTQ student wrote a powerful piece for Speech
&amp; Debate called <em>Maricon</em>, in which he detailed how this
word—akin to “faggot” in Spanish—was used by certain family members, and
had haunted his youth. Are conservative "parents' rights" advocates
actually suggesting that these students' teachers should betray them to
their parents? </p>
<h2>Dr. Janie Dam's Candidacy</h2>
<p>Originally another pro-charter candidate was running–Granada Hills
Charter School’s testing coordinator <a href="https://janiedam.com/">Dr.
  Janie Dam</a>. The Granada charter empire wants the public to believe
that their test scores are better than ours because they're better than we
are. The fact that their students are vastly more likely to come from
educated middle class families than Monroe's, they assert, has nothing to
do with it. We all know, of course, that it has everything to do with it.
</p>
<p>Early on Dr. Dam was the candidate who had raised the most money, and she
looked formidable. While we obviously would be opposed to Dr. Dam, I was
happy to see her candidacy, as it might well have split the charter vote,
throwing the election to Schmerelson. </p>
<p>Regrettably, the charter lobby apparently came to the same conclusion–I
guess we agree on one thing anyway–and Dr. Dam dropped out of the race. </p>
<h2>Who is UTLA Endorsing in Other School Board District Races?</h2>
<p>School Board Districts 2, 4 and 6 are not up for election until 2026.</p>
<h2>District 5</h2>
<p>Broadly similar to District 1, in District 5 Board Member Jackie
Goldberg, also generally pro-UTLA, is retiring.</p>
<p>Here UTLA endorses LAUSD special education teacher and parent <a href="https://karlagriegoforschoolboard.com/">Karla
  Griego</a>. She faces three opponents: LAUSD administrator <a href="https://www.gracielaortiz.com/">Graciela
  Ortiz</a>, classroom teacher <a href="https://www.gallardoforschoolboard.com/">Fidencio
  Gallardo</a>, and former classroom teacher, school dean and principal <a
  href="https://www.victorioforschoolboard.com/">Victorio Gutierrez</a>.</p>
<p>According to publicly available records, Gutierrez has raised little
money and probably has little chance. Ortiz has raised the most money so
far, and <em>City Watch LA</em> <a href="https://www.citywatchla.com/la-election-2022/28115-unusual-donations-raise-ethical-concerns-in-graciela-ortizs-school-board-campaign">points
  out some oddities among the contributions to her campaign</a>.</p>
<p>Griego <a href="https://utla.net/why-im-walking-for-karla-griego/#:%7E:text=She%20has%20been%20a%20UTLA,you%20need%20to%20get%20involved">has
  been a community organizer</a> for <a href="https://www.schoolslastudentsdeserve.com/who-are-we.html">Students
  Deserve</a>, as well as a UTLA North Area leader and a Chapter Chair</p>
<h2>District 7</h2>
<p>In District 7, <a href="https://www.tanyaforlausd.com/">incumbent Tanya
  Ortiz Franklin</a> is running against Long Beach Unified School District
second grade teacher <a href="https://www.lydia4education.com/">Lydia
  Gutiérrez</a>. UTLA has not made an endorsement in this election.
Perceived as being pro-corporate charter, Ortiz won her seat in 2020. She
was not endorsed by UTLA, and generally has not been supportive of us.</p>
<h2>Other Matters</h2>
<h2>Warning–LAUSD on the Hunt for Wayward Teachers</h2>
<p>UTLA Area Representative Tom Alfano says the district has been ramping up
pressure on schools to crack down on any inappropriate behavior by
teachers as a way of forestalling lawsuits. He says that when LAUSD is
sued it’s much easier when they can show that they investigated the
incident, disciplined and/or transferred the teacher, etc.</p>
<p>The district has had to pay out a lot of money in lawsuits as of late.
Combine that with the fact that the overwhelming majority of LAUSD
students live below the poverty line, and students and their families have
a strong incentive to make false accusations against teachers.</p>
<p>Tom’s advice is “Be Careful!” </p>
<h2>New Attacks on LAUSD's Black Student Achievement Program&nbsp;</h2>
<p>Prominent conservative Heather Mac Donald, a <em>New York Times</em>
bestselling author, is a frequent critic of teachers unions and many of
the programs we advocate. Support for the Black Student Achievement
Program was one of the issues in our 2023 contract fight and strike. With
Black History Month approaching, Mac Donald recently ripped into the BSAP
(and us) in her <em>City Journal </em>piece <a href="https://www.city-journal.org/article/los-angeles-schools-are-funding-for-failure">Funding
  for Failure</a>.</p>
<p>I responded in <a href="https://www.realcleareducation.com/articles/2024/01/01/conservative_attack_on_program_to_help_black_students_is_misguided_1002126.html">Conservative
  Attack on Program to Help Black Students Is Misguided</a> (<em>RealClearEducation.com</em>,
1/1/24), and Ballotpedia covered it in <a href="https://news.ballotpedia.org/2024/01/10/recommended-subject-line-hall-pass-your-ticket-to-understanding-school-board-politics-edition-92/">On
  the issues: The debate over Los Angeles’ Black Student Achievement Plan</a>.</p>
<p>She and I exchanged emails afterwards, and while I'll give her credit for
being a good sport, her responses, like her article attacking us, reflect
a problem central to the criticisms of public education, its alleged
failures, teachers, and teachers unions–our critics simply do not
understand just how badly the deck is stacked against many of our
low-income students.</p>
<p>Because of this lack of understanding, they consistently underestimate
the challenges those students, their teachers and their schools face, and
thus judge us harshly and unrealistically. </p>
<p> Monroe High School’s student body has one of the lowest socioeconomic
levels in the entire United States– because of this, we are exactly the
kinds of teachers that are so often vilified as incompetents who teach at
“failing schools.”</p>`
}