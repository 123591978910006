import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import redirects from './data/redirects'
import Home from './components/Home'
import Update from './components/Update'
import UpdateNotFound from './components/UpdateNotFound';
import { ViewAllButtonProvider } from './components/ViewAllContext'
import { formatDate } from './utlis/formatData'

import recentUpdates from './data/recentUpdates/recentUpdates'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Updates from './components/Updates';
import PrecinctWalking from './components/PrecinctWalking';
import ChangSupporters from './components/ChangSupporters';


const App = () => {
  return (
    <React.Fragment>
      <Router>
        <ViewAllButtonProvider>
          <Switch>
            {redirects.map(redirect => < Redirect key="{redirect.old}" from={
              `/${redirect.old}.html`
            }
              to={
                `/update/${redirect.new}`
              } />)}
            <Route
              exact
              path="/"
              render={() => (<Home updates={recentUpdates} formatDate={formatDate} />)} />
            <Route
              exact
              path="/updates"
              render={() => (<Updates updates={recentUpdates} formatDate={formatDate} />)} />

            <Route
              exact
              path="/precinct-walking"
              render={() => (<PrecinctWalking />)} />

<Route
              exact
              path="/a-closer-look-at-changs-other-supporters"
              render={() => (<ChangSupporters />)} />

            <Route
              exact
              path="/update/:id"
              render={(props) => (<Update updates={recentUpdates} {...props} formatDate={formatDate} />)} />
            <Route path="*" component={UpdateNotFound} />
          </Switch>
        </ViewAllButtonProvider>
      </Router>
    </React.Fragment>
  )
}

export default App;
