module.exports = {
id:119,
title: 'Update on Battle over LAUSD\'s Out-of-Classroom Personnel Work Hour Extension',
date: '2024-05-06',
content: ` <p>As we’ve discussed, budgets at individual schools are taking six figure
losses because Los Angeles Unified School District superintendent Alberto
Carvalho is taking back 70% of local rollover budget surpluses. UTLA is
rallying against these cuts at Beaudry at 4:30 p.m. this Tuesday, May
7th–if you're interested in joining us, please contact your Chapter Action
Team leader or reply to this email.</p>
<h2>The Latest on LAUSD’s Out-of-Classroom Personnel Work Hour Extension
Demand</h2>
<p>As we<a href="https://monroeutla.com/update/118"> discussed a couple of
  weeks ago</a> and has been noted in recent UTLA emails, the district is
attempting to extend the on-site obligation of out-of-classroom personnel
from six hours a day to eight. UTLA Elementary Vice-President Maria
Miranda explains that the district has attempted to use some inconsistent
and unclear language in the contract to “pull a fast one” on roughly 2,000
UTLA bargaining unit members.</p>
<p>LAUSD attempted to ram this through instead of openly negotiating it with
UTLA, but UTLA pushed back and we are now in negotiations over a
sideletter on this issue. Part of the problem has been that out of
classroom personnel have been dealt with unevenly throughout the district,
depending on the school site. One thing we expect to come out of these
negotiations is uniformity.</p>
<p>After evasions and dithering, LAUSD <a href="https://utla.net/app/uploads/2024/04/District-Proposal-Non-Classroom-Positions-On-Site-Obligation-4.10.24.pdf">
 made a concrete proposal to UTLA</a> on April 10. Because initial
proposals and counterproposals are usually the most labor-intensive,
things moved a little slowly, but UTLA<a href="https://utla.net/app/uploads/2024/05/UTLA-Counter-Proposal-5124.pdf">
  submitted a counterproposal</a> on May 1.</p>
<h2>UTLA Counterproposal: a $10K a Year Differential</h2>
<p>Incredibly, the differential that is offered to out-of-classroom
personnel has not been increased in at least 20 years, perhaps longer–it’s
been so long that they can’t even figure out the last time it was
increased. UTLA is asking for a $5,000 per semester differential, and that
the differential increase by the same percentage as the raises we
negotiate in future contracts.</p>
<h2>UTLA Counterproposal: Extra Pay for Out-of-Classroom Personnel When They
Cover Classes</h2>
<p>Covering classes can weigh on out-of-classroom personnel like a
stone–after all, they still have their regular work they need to get done.
Another UTLA demand is that if out-of-classroom personnel are spending 50%
or more of their time substituting, they “shall be offered additional
hours equal to the amount of hours pulled from their assignment. Those
hours shall be compensated at their hourly rate in addition to their
regular pay.”</p>
<p>For secondary, 50% is defined as three periods per day.</p>
<h2>Will the New Obligations Apply to Counselors?</h2>
<p>UTLA’s counterproposal is that “Incumbent school site counselors may
accept a differential and/or stipend if offered to extend their on-site
obligation but shall not be required to do so.”</p>
<h2>LAUSD Chiseling Its SEIU Workers</h2>
<p>UTLA members joined SEIU Local 99 members on informational picketlines
last Wednesday to protest LAUSD’s attempts to undo some of what SEIU won
in the March 2023 strike. </p>
<p>One big issue for SEIU is that LAUSD keeps paraprofessionals at only six
hours a day so they won’t be considered full-time employees. As such, the
District can adjust their schedules or reduce their hours whenever they
want.</p>
<p>Ending this is one of the gains SEIU made in its contract. However, LAUSD
has been dragging its feet on implementation–this provision was supposed
to be implemented by January, but LAUSD has not yet done so.</p>
<h2>A Note to Prob 1 Teachers</h2>
<p>One of the biggest struggles newer teachers face is the enormous burden
of preparing and planning lessons. In recognition of this, Prob 1 Teachers
have the right to stay in their current grade level. As Valley East Area
Chair Scott Mandel advises, “this can be changed only through agreement of
the teacher and the principal.”</p>
<p>If you're a newer teacher and you're concerned about this, please reach
out to Monroe UTLA Chair Lourdes Quevedo at <a href="mailto:jmhs.UTLA@gmail.com">jmhs.UTLA@gmail.com</a>.</p>
<h2>Update Your Information with UTLA at Monroe</h2>
<p>It is important for UTLA at Monroe to have up-to-date contact information
for all of our members. Sometimes members change their phone numbers, and
sometimes the email address we have for you is one you have discontinued,
or rarely check, or have forgotten about. Particularly with email, we
generally have no way of knowing that our communications are not reaching
you.</p>
<p>If your information has changed, please reply to this email with any
updates and I will make sure they get into each part of Monroe UTLA’s
database.</p>`
}