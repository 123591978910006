module.exports = {
id:125,
title: 'The Battle Against Pro-Charter Candidate Dan Chang',
date: '2024-09-23',
content: `<p>As we discussed a few weeks ago, we need to help <a href="https://boardmemberscott.org/">Scott Schmerelson</a>, a longtime friend and ally of teachers and teachers unions, win LAUSD School Board District 3. We will be precinct walking for Schmerelson in District 3, as many of us did during the March 3rd primary election.</p>
<h2>Sign-Up for Precinct Walking</h2>
<p>We will be walking two weekends&ndash;the weekend of October 5 &amp; 6 and of October 19 &amp; 20. Monroe UTLA has selected these weekends because they bracket October 7th, which is when <a href="https://www.sos.ca.gov/elections/upcoming-elections/general-election-nov-5-2024">California counties will begin</a> mailing out vote-by-mail ballots. All California active registered voters will receive a vote-by-mail ballot. Ballot drop-off locations open on October 8, 2024.</p>
<p>We will supply you with all needed materials to precinct walk, plus snacks and water&ndash;what we're looking for from you is a four-hour commitment. To sign up, simply reply to this email and tell us which date(s) you'd like to walk.</p>
<p>Thanks to those of you who have already signed up to precinct walk.</p>
<h2>As We Approach Contract Negotiations, Who Will Be on the Other Side of the Bargaining Table?</h2>
<p>We thank all of the Monroe UTLA members who stopped by my room (F-6) after school the week before last to give their input on what our contract bargaining priorities should be. We begin negotiating early next year and it will matter whether we are negotiating with a school board that values teachers or one which doesn't.</p>
<p>Scott Schmerelson has shown repeatedly that he values teachers, Dan Chang has shown repeatedly that he does not, including when, according to UTLA, he crossed our picket lines during both the 2019 and 2023 strikes.</p>
<p>Schmerelson&rsquo;s term ends December 16. If Chang is elected and becomes part of an anti-UTLA LAUSD School Board majority, negotiating a good contract will be much more difficult, and could even end up forcing us to strike again. </p>
<h2>Scott Schmerelson Pledges to Fight Any Monthly Premiums for Our Healthcare</h2>
<p>LAUSD educators are one of the very few groups in the US who still receive premium free health care from our employer. If anybody doesn't understand what a big deal this is, I invite you to listen to how much UTLA&rsquo;s political opponents complain about it.</p>
<p>At the August UTLA Leadership Conference Schmerelson pledged to fight any monthly premiums for our healthcare.</p>
<p>In the past LAUSD has tried to cut our healthcare&ndash;one time they even put a number on how much they&rsquo;d like to cut our healthcare&mdash;44%. Some of the cuts they've tried to push on us have included:</p>
<ul>
<li>Making us pay $500 a month towards our healthcare benefits.</li>
<li>No dependent healthcare. So, we&rsquo;d get free healthcare but would have to pay for it out of pocket for our spouses and children.</li>
<li>Reducing or eliminating healthcare for LAUSD retirees</li>
</ul>
<p>Schemerleson says he feels that some of the younger members of the school board don't fully understand the importance of the healthcare benefits for LAUSD employees.</p>
<h2>Schemerleson Pledges to Defend SEIU Workers Against LAUSD Health Benefits Chicanery</h2>
<p>SEIU employees need four hours work hours a day to qualify for healthcare, and he said that some principals are giving them schedules with 3.75 hours a day on it. He pledges to fight this.</p>
<h2>Other Schemerleson Priorities</h2>
<p>At the UTLA conference and since, Schemerleson has outlined some of his other priorities. These included:</p>
<ul>
<li>Class sizes</li>
<li>The Black Student Achievement Program</li>
<li>Getting proper federal funding for the Individuals with Disabilities Education Act (IDEA)</li>
<li>The problems faced by LAUSD foster youth</li>
</ul>
<h2>What Does Dan Chang Stand for?</h2>
<p>As is typical of school board campaigns, most of what Dan Chang has said are vague statements targeting the common education villains&ndash;&ldquo;bureaucracy&rdquo;, &ldquo;low expectations&rdquo;, etc &ndash;with few specifics. However, in his comments one can decipher some troubling ideas.</p>
<h2>Does Dan Chang Oppose Teachers&rsquo; Autonomy?</h2>
<p>In July, Dan Chang <a href="https://laist.com/news/politics/2024-election-california-general-los-angeles-county-lausd-school-board-district-3">told LAist</a>:</p>
<p>&ldquo;[O]ne of the missing ingredients is really our expectations for adults in the classroom, right? &hellip; I go to a myriad of trainings, like once a week. Other than my first year of teaching, I can't think of a single time an administrator has actually come into my room and actually said, &lsquo;Hey, like, this thing you were just trained on, are you doing it?&rsquo;&rdquo;</p>
<p>It is important that teachers have the autonomy and flexibility to do what we need to do to serve the students who are in front of us. The converse is top-down District mandates for what teachers must do in the classroom. The pretext for this is low test scores stripped of their context&ndash;the often challenging personal circumstances that Los Angeles Unified School District students face.</p>
<p>Monroe High School has been deemed a &ldquo;priority school&rdquo; since July 1st. This makes us a target for District dictates, scolding, and meddling simply because the students we teach are at such a low socioeconomic level. In Chang, LAUSD Superintendent Alberto Carvalho would have an eager ally.</p>
<h2>Who Endorses Chang?</h2>
<p>On Chang&rsquo;s website <a href="https://www.chang4change.org/endorsements-1">he lists</a> some of his most prominent endorsers. Not coincidentally, they include many of UTLA's opponents from recent years.</p>
<p></p>
<h2>Nick Melvoin</h2>
<p>Front and center on Chang's endorsement page is LAUSD Board Member Nick Melvoin, who seems to be Chang&rsquo;s role model.</p>
<p>Like Chang, Melvoin came to the Board as a young candidate with a smooth message. Just as Chang hopes to ride wealthy donors&rsquo; dollars to victory, in 2017 the California Charter Schools Association and its billionaire supporters poured more than $10 million into Melvoin&rsquo;s successful school board election campaign.</p>
<p>In 2022 Melvoin was reelected with support from the CCSA, billionaire Bill Bloomfield, and other anti-UTLA interests. He was former LAUSD superintendent Austin Beutner's closest ally on the board and is arguably Carvalho&rsquo;s closest ally today.</p>
<p>Our 2019 strike was so overwhelming that in time most of the LAUSD School Board abandoned Beutner, leaving him to twist in the wind. Melvoin, by contrast, remained loyal to his anti-union paymasters up to the end, reminding one of the famous statement from 19th century businessman Simon Cameron &ndash; &ldquo;An honest politician is one who, when he's bought, stays bought.&rdquo;</p>
<p></p>
<h2>Antonio Villaraigosa</h2>
<p>Also at the top of Chang&rsquo;s endorsement page is former Los Angeles Mayor Antonio Villaraigosa, who has called UTLA &ldquo;the largest obstacle to creating quality schools.&rdquo;</p>
<p>Villaraigosa attacked UTLA members&rsquo; seniority protections and supported using student test scores to evaluate teachers. He also attempted to create mayoral control of the Los Angeles Unified School District. The CCSA backed him in his unsuccessful 2017 gubernatorial campaign.</p>
<p></p>
<h2>Monica Garcia</h2>
<p>Former LAUSD Board President Monica Garcia, a longtime opponent of UTLA whose political career has been partially bankrolled by the CCSA, is another prominent Chang supporter.</p>
<p>According to Rutgers University. assistant professor of labor studies Eric Blanc, &ldquo;<a href="https://jacobin.com/2019/01/los-angeles-teachers-strike-antiracism-unions">On day one of the [2019] strike</a>, she dutifully repeated Superintendent Austin Beutner&rsquo;s absurd claim that only 3,500 teachers were on the picket lines&hellip;she claimed [UTLA] was &lsquo;only hurting students, families and communities&rsquo;.&rdquo;</p>
<p>According to Blanc, Garcia &ldquo;pushed charter schools as &lsquo;the civil rights issue of our time&rsquo;.&rdquo; I guess in her mind this makes pro-union teachers like us the Southern sheriff blocking the schoolhouse door&hellip;</p>
<h2>Who Bankrolls Chang?</h2>
<p>In <a href="https://monroeutla.com/update/116">The Hidden Forces Behind Chang&rsquo;s Expensive New Mailers</a> (<em>Monroe Update #116</em>, 01/21/2024), I detailed the various wealthy, anti-public school, anti-ULTA donors bankrolling Chang&rsquo;s campaign. It demonstrates exactly where Chang stands.</p>
<p>His donors include:</p>
<ul>
<li>Wealthy charter supporter <a href="https://capitalandmain.com/bill-bloomfield-the-rainmaker-1101">Bill Bloomfield</a>, who has donated hundreds of thousands of dollars to defeat Scott Schmerelson in 2024. To learn more about Bloomfield and the long line of anti-public education, anti-teachers union candidates and causes he has backed, see Joel Warner&rsquo;s <a href="https://capitalandmain.com/bill-bloomfield-the-rainmaker-1101">Bill Bloomfield: Big Dollars for Unregulated Education</a>.</li>
<li>California Republican Party General Counsel Ashlee Titus, who is the president of the Sacramento branch of the Federalist Society, a conservative legal organization with an enormous influence in Republican circles. The Federalist Society is a big supporter of religious charter schools, and played a large role in both the Trump and the George W Bush administrations. They also were involved in Citizens United v. FEC, the US Supreme Court's controversial 2010 ruling that greatly weakened campaign finance laws. It paved the way for an increased influence of big money in politics&ndash;like what Chang is receiving right now.</li>
<li>The Charter Public Schools PAC, which has given at least $200K to Kids First, Supporting Dan Chang for LAUSD School Board 2024</li>
</ul>
<h2>Schmerelson v Chang Will Be a Close, High-Turnout Election</h2>
<p>Polls show that the Schmerelson v Chang race is very close. And because it&rsquo;s a presidential election year, this will be a high turnout election. </p>
<p>Normally high turnout elections are good for Democrats and liberals, and low turnout elections are good for conservatives and Republicans. Schmerelson speaks proudly of his endorsement by the Democratic Party and its allies, including various labor groups.</p>
<p>The fact that Schmerelson beat Dan Chang 45% to 29% in a low turnout election&ndash;the March 5th primary&ndash;is also encouraging. However, there were several minor candidates also running in March, generally leaning to the conservative side, at least by liberal Los Angeles and California standards. The minor candidates probably took more votes away from Chang than they did Schmerelson, perhaps many more.</p>
<h2>Does the Democrat v Republican Dichotomy Apply in this Election?</h2>
<p>Obviously when it comes to education-related issues, including charter schools, teachers unions, etc.-- Chang is on the right and Schmerelson is on the left. However, Los Angeles is a liberal city in a liberal state and generally speaking, relatively conservative/pro-Republican candidates tend to keep that under wraps. To my knowledge, Chang has not made any clear statements as to where he stands in relation to the two major political parties, and it is unclear if the Democrat v Republican dichotomy will mean much here.</p>
<h2>Brief AP Government Lesson&ndash;Why Do High Turnout Elections Help Democrats &amp; Hurt Republicans?</h2>
<p>A brief AP Government lesson for those interested&ndash;political scientists assert that the reason why high turnout helps Democrats is that much of the Democratic Party's base are non-whites, often with immigrant backgrounds. African Americans, for example, have been the Democratic Party's most reliable voting bloc since Franklin Roosevelt won the 1932 presidential election.</p>
<p>Lower income people are more likely to change jobs and move, and are less likely to lead settled lives. Because of this, they are more likely to be episodic voters.</p>
<p>Moreover, the Democratic Party tends to be less united than the Republican Party&ndash;as Will Rogers once said, "I am not a member of any organized political party. I am a Democrat.&rdquo;</p>
<p>By contrast, despite the Republican Party's efforts to reach out to minority voters, the Republican base is more likely to be white, relatively stable, and focused on voting. In the United States as a whole, 56% of whites are registered Republicans compared to 41% were registered Democrats.</p>
<p>If it's a high turnout election, that means that a lot of people traditionally associated with the Democratic Party came out to vote. If it's a low turnout election, they didn't, leaving the field to Republican Party voters who are smaller in number but generally more consistent in their voting habits.</p>`
}