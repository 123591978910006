import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import MainNavBar from './MainNavBar';
const ChangSupporters = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <><MainNavBar noLink={true} /><Container>
            <main>
                <article>
                    <h1>A Closer Look at Chang's Other Supporters</h1>
                    <p>Beyond the multi-millionaires and California Republican party bigwigs
                        stealthily funding Chang’s campaign, he is also drawing campaign
                        contributions from a wide variety of charter organizations including
                        charter networks, charter schools, charter management organizations, and
                        others.
                    </p>
                    <p>Looking on the website <a href="https://ethics.lacity.org/data/campaigns/contributions/">
                        https://ethics.lacity.org/data/campaigns/contributions/</a> reveals some
                        telling information. Chang’s backers include:
                    </p>
                    <ul>
                        <li><a href="https://www.kipp.org/board-of-director/kathering-bradley-chair/">Katherine
                            Brittain Bradley</a>, chairman of the board of the KIPP charter school
                            network</li>
                        <li>The New Majority PAC, which counts the election of U.S. Senate
                            Republican leader <a href="https://newmajority.com/victories/"> Mitch
                                McConnell as one of its “victories.”</a> Another of the “victories” it
                            boasts of was defeating Measure EE, the UTLA-supported June 2019 ballot
                            measure that would have increased funding for schools! (To learn more
                            about Measure EE, see <a href="https://www.dailynews.com/2019/03/10/lausd-needs-proper-funding-the-parcel-tax-will-help/">
                                LAUSD needs proper funding. The parcel tax will help</a><em>, Los
                                Angeles Daily News, </em>3/10/19).</li>
                        <li>Elizabeth Saiger, Operations Manager of <a href="https://www.ednovate.org/">
                            Ednovate</a>, which describes itself as a “network of independent
                            public charter schools that spans across Los Angeles and into Orange
                            County.”</li>
                        <li>Gregory Mcginity, Executive Director of <a href="https://www.ccsaadvocates.org/">
                            California Charter Schools Association Advocates</a>. Their website
                            states that charter schools “are under attack in Sacramento. Special
                            interests want to protect their privileges at the expense of our
                            students and families.” And let’s be clear about those “special
                            interests”--they mean us.</li>
                        <li>Yvonne Chan, an Education Consultant for <a href="https://www.myvaughncharter.com/about-us">
                            Vaughn Next Century Learning Center</a>, a charter school which
                            operates five contiguous campuses</li>
                        <li>Michael Davis, CEO of Purpose Built Schools, a Georgia based
                            pro-charter organization</li>
                        <li>Adriana Abich, CEO of <a href="https://www.caminonuevo.org/"> Camino
                            Nuevo Charter Academy</a> in Los Angeles</li>
                        <li>Anita Landecker, Executive Director of <a href="https://www.linkedin.com/company/exed/">
                            ExED</a>, the back office service provider for California charter
                            schools</li>
                        <li>Venture capital investor Marshall Tuck, former President of Green Dot
                            charter schools and two-time candidate for California Superintendent of
                            Public Instruction.</li>
                        <li>Caprice Young of charter school operator <a href="https://www.navigatorschools.org/">
                            Navigator Schools</a></li>
                        <li>Parker Hudnut of the charter school management organization <a href="https://www.icefps.org/apps/pages/index.jsp?uREC_ID=3342589&amp;type=d&amp;pREC_ID=1499669">
                            Inner City Education Foundation</a></li>
                        <li>Amy Held, a “School Leader” at <a href="https://www.larchmontcharter.org/">
                            Larchmont Charter School</a> in Culver City</li>
                        <li>Melissa Kaplan, Executive Director of the charter network<a href="https://cwclosangeles.org/">
                            Citizens of the World Charter Schools Los Angeles</a></li>
                        <li>Elijah Sugay, Chief Business Officer of<a href="https://brightstarschools.org/">
                            Bright Star Schools</a>, a charter school organization that operates
                            nine schools across Los Angeles</li>
                        <li>Eric Barlow, manager at the charter operator<a href="https://www.stem-prep.org/">
                            Stem Preparatory Schools</a></li>
                        <li>Ana Teresa Dahan, Managing Director of Great Public Schools Now, a
                            group which, <a href="https://www.latimes.com/local/education/la-me-edu-gpsn-grants-for-new-lausd-school-20160929-snap-story.html">
                                according to the <em>Los Angeles Times</em></a>, “spawned from a
                            controversial plan for rapid charter-school growth…the organization was
                            born out of a confidentially circulated plan, obtained by <em>The Times</em>,
                            that envisioned pulling half the students from the nation’s
                            second-largest school system into charter schools. Charters operate
                            independently of L.A. Unified and their growth to date — with about 16%
                            of district enrollment — is one important factor contributing to the
                            district’s budget woes because education dollars follow the
                            students…Critics of the original charter-growth plan said that it would
                            bankrupt L.A. Unified and asserted that such an outcome was probably one
                            of the goals.” <em>The Times</em> notes that this plan was “spearheaded
                            by the Eli and Edythe Broad Foundation”, major anti-public school
                            privatizers.</li>
                    </ul>
                </article>
            </main>
        </Container></>
    )

}

export default ChangSupporters