module.exports = {
id:118,
title: 'May 7 Rally to Fight Carvalho’s Cuts, UTLA Members Targeted for Longer Work Day',
date: '2024-04-24',
content: ` <p>Despite possessing a staggering $6.3 billion dollar reserve, LAUSD is
taking 70% of carryover funds away from schools–as opposed to the norm of
~30%--forcing schools to cut personnel. UTLA President Cecily Myart-Cruz
describes these cuts as being “without precedent.”</p>
<p>UTLA is <a target="_blank" href="https://utla.net/event/stop-the-carvalho-cuts-rally/">rallying</a> outside the LAUSD school board meeting at Beaudry the
afternoon of Tuesday May 7th to protest these cuts.</p>
<p>We must go beyond debating what should be cut from a school's budget and
fight these cuts as a whole.</p>
<p>Your Chapter Action Team leaders will soon be in touch with you
concerning arrangements to go to the Tuesday, May 7th rally–if you want to
participate, contact your CAT leader or simply reply to this email.</p>
<h2>‘You Have the Money to Fund our Schools–You Are Just Refusing to Do It’</h2>
<p>In a recent letter to Superintendent Alberto Carvalho, Myart-Cruz notes
that “many schools rely on carryover funds to fill the serious budget
gaps" and accuses LAUSD of “failing to provide sufficient school site
budget allocations to maintain the positions that schools have relied on
for safety, school cleanliness, instruction, and student support.”</p>
<p>Myart-Cruz writes:</p>
<blockquote>“You have the money to fund our schools --- you are just refusing to do
it. The 2024-2025 budget is not finalized. We demand you adjust the budget
to provide enough funds to each school site to maintain any and all
existing site-funded positions and return the carryover money back to
schools.”</blockquote>
<p>Myart-Cruz’s letter also criticizes LAUSD’s “lack of transparency around
local school site budgets” and “budget related directives that are
destabilizing our schools”, and alleges that “programmatic cuts will
impact every school site.”</p>
<p>Next year we will bargain both our new contract and our new healthcare
agreement. Though our successful March 2023 solidarity strike strengthened
our position, if we don’t fight these new cuts, our bargaining position
will be weakened.</p>
<h2>Why Is Carvalho Cutting School Budgets?</h2>
<p>One reason for the cuts is Carvalho’s desire to improve LAUSD’s bond
rating. The rating agencies Moody's and Fitch both recently upgraded
LAUSD's bond ratings. According to <a target="_blank" href="https://www.bondbuyer.com/news/lausd-bonds-get-ratings-boost-ahead-of-babs-refunding"><em>The Bond Buyer</em></a>:</p>
<blockquote><p>“Moody's cited the district's improved general fund balance for the
upgrade, saying it will likely remain above 35% through fiscal 2026, even
as the district reduces spending to accommodate future reductions in state
funding.</p>
<p>"’Governance is a key driver of the rating,’ Moody's analysts said,
citing the district's multiyear planning, wage agreements, cost cutting
and efforts to strengthen attendance and maximize the operating efficiency
of school campuses.”</p></blockquote>
<p>In other words, what Moody’s analysts call Carvalho’s “conservative
budgeting practices” and spending cuts have made it less expensive for the
district to borrow money–as LAUSD’s bond rating rises, the interest rates
it is charged go down.</p>
<h2>Union Opponents’ <i>Modus Operandi</i></h2>
<p>Whenever a union makes demands, there is a chorus of foreboding warnings
from whatever organization, business or industry being targeted. Sometimes
the stronger the union’s position is, the more outlandish the warnings.</p>
<p>Some of you may recall the dire warnings leading up to the 2019 strike,
as we were continually lectured about how we were irresponsibly pushing
the district into a crisis by demanding they invest non-existent funds
into our schools. As we moved closer to the strike, there was revelation
after revelation that the district had vastly more in its reserves than
they had originally claimed.</p>
<p>Don't be surprised if at some point UTLA is accused of acting
irresponsibly and undermining LAUSD's efforts to increase its bond
ratings, among other accusations. Yet when labor is successful in winning
its demands, what actually ends up happening is that, despite the
apocalyptic predictions, the organizations and businesses involved adjust
to the new reality relatively quickly.</p>
<h2>LAUSD Targeting Out-of-Classroom UTLA Personnel</h2>
<p>Most out-of-classroom personnel have the same daily work hours as
classroom teachers–six on-site and two at our discretion. As Monroe UTLA
Chair Lourdes Quevedo reported in mid-March, LAUSD is attempting to change
UTLA out-of-classroom on-site obligations from six hours a day to eight.
There are roughly 2,000 UTLA members who are impacted by this.</p>
<p>Needless to say, this presents various issues for our members,
particularly regarding childcare and other family-related arrangements. We
have been attending the meetings on this issue, and I wish we had better
news to report. What we’ve learned is as follows:</p>
<ul>
<li>In March and in April UTLA had bargaining sessions with the district
  and asked the district to rescind the new orders, and make the two hours
  at UTLA members' discretion, as in the past.&nbsp;</li>
<li>UTLA Elementary Vice-President Maria Miranda has been taking the lead
  on this issue, and she says the district is “trying to pull a fast one”
  but that, regrettably, there is some inconsistency/lack of clarity in
  the contract language (Article 9 Section 3.5A &amp; 3.5C).&nbsp;</li>
<li>Unfortunately, at first LAUSD did not take the LAUSD-UTLA bargaining
  sessions on the issue very seriously–in the first session the district
  showed up with nothing. Even after the second meeting, there was still
  nothing in writing.</li>
<li>One problem is that there are some discrepancies between schools and
  areas as to how the six hour versus eight hour issue has been handled.
  Miranda says, “the more we dig [into this issue], the messier it gets.”</li>
<li>Regardless, UTLA Grievance Coordinator Carl Joseph says, “nothing in
  the contract mandates eight hours on site without qualifications.”</li>
<li>LAUSD agreed with UTLA to cease and desist with coordinator elections
  until this issue is resolved.</li>
</ul>
<p>Incredibly, the differential that is offered has not been increased in at
least<em> 20 years</em>, perhaps longer–it’s been so long that they can’t
even figure out the last time it was increased. </p>
<h2>LAUSD Finally Makes a Concrete Proposal</h2>
<p>After over a month of evasions and dithering, LAUSD has finally made a
concrete proposal to UTLA on this issue. That’s the good news–the bad news
is that LAUSD’s proposal contains no real steps forward.</p>
<p>UTLA will soon be responding with a counterproposal–it will be available
on the UTLA website, and we will keep you up-to-date on it.</p>
<h2>Who Will Be Affected by This?</h2>
<p>The current list of affected positions includes:</p>
<ul>
<li>Categorical Program Adviser – Title 1 Coordinator - 4</li>
<li>Targeted Student Population (TSP) Program Advisor - 4 all other two</li>
<li>Community School Coordinator -&nbsp;</li>
<li>Dean of Students -&nbsp;</li>
<li>International Baccalaureate (IB) Coordinator</li>
<li>Intervention Prevention Support Coordinator</li>
<li>Magnet Coordinator</li>
<li>Middle School – College and Career Coordinator</li>
<li>Problem Solving Data Coordinator</li>
<li>Restorative Justice Teacher</li>
<li>Technology Coordinator</li>
<li>Special Education Support Provider</li>
<li>General Education Support Provider</li>
</ul>
<h2>Will Counselors Be Affected?</h2>
<p>According to Miranda, LAUSD has not made any indication that counselors
will be included.</p>
<h2>Be Careful--Monroe Teachers Targeted for Discipline</h2>
<p>As we have previously advised you, at both Monroe and throughout LAUSD as
a whole, we’ve seen a huge spike in disciplinary proceedings. At some
schools, disciplinary proceedings for UTLA members are being
commenced 12 or even 18 months after the alleged incident. Our advice is:</p>
<ul>
<li>Watch yourself–while obviously we cannot get into details, some are
  being disciplined unjustly.</li>
<li>Don’t go into a disciplinary proceeding without UTLA representation–if
  you receive an “Invitation to Conference” email re: a potential
  disciplinary action, please contact us immediately. One of us, usually
  Ms. Quevedo, will accompany you and advise you, and we will help you
  craft your response.</li>
<li>Don’t discuss your case with an administrator. We understand it can be
  extremely tempting to dispute the charges right away. I don’t like to
  compare these to criminal law proceedings, but any criminal defense
  attorney will tell you their #1 piece of advice is “be quiet!” Defense
  attorneys often complain that after an arrest, before the defense
  attorney has even met with their new client, the accused has already
  incriminated themselves.</li>
</ul>

<h2>Update Your Information with UTLA at Monroe</h2>
<p>It is important for UTLA at Monroe to have up-to-date contact information for all of our members. Sometimes members change their phone numbers, and sometimes the email address we have for you is one you have discontinued, or rarely check, or have forgotten about. Particularly with email, we generally have no way of knowing that our communications are not reaching you.</p>
<p>If your information has changed, please reply to this email with any updates and I will make sure they get into each part of Monroe UTLA’s database.</p>


`
}