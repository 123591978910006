import React, {useState, useEffect} from 'react'
import Footer from './Footer'
import Container from 'react-bootstrap/Container';

import { useViewAll } from './ViewAllContext'
import MainNavBar from './MainNavBar';


const Update = props => {

    const [content, setContent] = useState('loading')
    const useViewAllContext = useViewAll()


    useEffect(() => {

        let update = props.updates.filter(update => update.id === + props.match.params.id)

        if (update.length > 0) {
            setContent(update[0])
            useViewAllContext.toggleViewAllButton()
        } else {
            props.history.push(`/not-found`)
        }

        return () => {
            useViewAllContext.toggleViewAllButton()
          };

    }, [props, content, useViewAllContext]);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <><MainNavBar /><Container>
            <main>
                <article>
                    <h1
                        dangerouslySetInnerHTML={{
                            __html: content.title
                        }} />
                    <div id="dateContainer">Update #{content.id} &middot; {props.formatDate(content.date)}</div>
                    {/* <p>Dear Colleagues:</p> */}
                    <div
                        dangerouslySetInnerHTML={{
                            __html: content.content
                        }} />

                    <Footer updateId={content.id} />
                </article>
            </main>
        </Container></>
    )

}

export default Update