import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import RecentUpdates from '../components/RecentUpdates';
import recentUpdates from '../data/recentUpdates/recentUpdates';
import { formatDate } from '../utlis/formatData';
import MainNavBar from './MainNavBar';

const Index = props => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <><MainNavBar /><Container>
      <main>
        <div className='container' style={{ marginTop: '7.0rem' }}>
          <p>Dear UTLA Colleagues:</p>
          <p>Monroe UTLA Updates are sent out to all Monroe teachers more or less on a monthly basis during the school year. In addition, depending on the pace of events, they are sent out as needed in order to help keep our members informed.</p>
          <p>Updates or portions of updates that do not discuss Monroe events or issues specifically are also sent out to those Valley chapter chairs and vice chairs who have signed up for them. If you want to receive the emails, please send me your non-LAUSD email address at <a href="mailto:glenn@glennsacks.com">glenn@glennsacks.com</a>.</p>
          <p>Best Wishes,</p>
          <p>Glenn Sacks<br />Vice Chair, Monroe UTLA, 2018 to 2024</p>
        </div>
        <RecentUpdates recentUpdates={recentUpdates} formatDate={formatDate} />
      </main>
    </Container></>
  )
}

export default Index