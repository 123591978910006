import React from 'react'
import {Link} from "react-router-dom";
import Container from 'react-bootstrap/Container';

const UpdateNotFound = props => {

    return (
        <Container>
        <main id="pageNotFound">
        <h1>Page Not Found</h1>
        <p>The page you requested could not be found. <Link to="/">Continue</Link></p>
        </main>
        </Container>
    )
}

export default UpdateNotFound