module.exports = {
id:120,
title: 'Carvalhos\' Enron Accounting',
date: '2024-05-19',
content: ` <p>A little over two decades ago, the energy company Enron
Corporation--which TIME magazine called “innovative” and “a darling of
Wall Street investors”--collapsed. It was the largest bankruptcy in U.S.
history.</p>
<p>Enron's cheerful reports of its financial condition were later revealed
to be based on massive accounting fraud. In the wake of the scandal,
deceptive and misleading accounting tactics came to be known as “Enron
Accounting.”</p>
<p>Until very recently, budgets at some individual Los Angeles Unified
School District schools were taking six figure losses because LAUSD
withheld 70% of local rollover budget surpluses. UTLA members will not be
surprised to learn that some of the claims Carvalho has used some to
justify these cuts are actually just “Enron Accounting”-style gimmicks.</p>
<p>UTLA Secondary Vice President Alex Orozco detailed some of these at last
week's Valley West Area meeting–below are the highlights of his remarks.</p>
<h2>‘Committed’ Funds That Aren’t ‘Committed’ to Anything</h2>
<p>One of the key pillars of Carvalho's imaginary budget crisis is
classifying what used to be called unassigned funds as "committed” funds.
But “committed” is the same as unassigned–these funds are not committed to
anything. The district currently has $964 million in “committed” but
unspent funds.</p>
<p>This particular accounting gimmick did not really appear until around the
time Carvalho came to LAUSD, when the amount of “committed” funds suddenly
skyrocketed. </p>
<h2>Artificially-Inflated Expense Estimates</h2>
<p>Another Carvalho accounting trick is to put aside for a particular budget
item vastly more money than he intends to spend or that the district has
ever spent on that item.</p>
<p>Carvalho has currently set aside $1.1 billion to spend on textbooks, even
though LAUSD consistently spends only ~40% of that total. Through this
accounting trick Carvalho deems untouchable an extra $655 million. This is
more than enough to cover all current spending cuts.</p>
<h2>Lavish Spending on Outside Consultant</h2>
<p>Orozco also notes that LAUSD is spending vastly more on outside
consultants and subcontractors than it has in the past. This spending is
$614 million more than it was just five years ago.</p>
<p>To be fair to Carvalho, there has been significant inflation in this
period of time. However inflated prices could not account for more than a
modest part of this rise in spending.</p>
<h2>A Budget Crisis or A Power Struggle?</h2>
<p>Beyond the large potential savings detailed above, Orozco says that one
could easily find hundreds of millions more dollars in unnecessary
spending that could be reallocated to cover school funding.</p>
<p>In numerous Monroe UTLA updates on the battles we’ve fought over the
years, we’ve noted that crying poverty is a common tactic for bosses,
whether it's a school district or a private company. Some of you may
recall that former LAUSD superintendent Austin Beutner was particularly
insistent on this point before and during our successful 2019 teacher
strike. Orozco explains:</p>
<blockquote>“We are not in a budget crisis–we're in a power struggle, just like 2019
and 2023.”</blockquote>
<h2>Unnecessary Hoarding of Reserves</h2>
<p>Beyond the above mentioned accounting gimmicks is the fact that LAUSD is
only legally required to keep 2% of its annual $13.5 billion budget as
reserves. The $6.3 billion Carvalho is fighting to keep in reserve is 42%
of LAUSD's annual budget–over <em>20 times</em> as much as is legally
required.</p>
<h2>UTLA-SEIU Beats Back Some of the Cuts</h2>
<p>In part because of the joint UTLA-SEIU rally at Beaudry on May 6 and the
widespread media coverage of it, Carvalho has had to back down to some
degree. UTLA recently explained:</p>
<blockquote>“Carvalho announced to the school board that the district will now return
some of the 70% carryover funding he had planned to withhold from schools
for the 2024-25 school year: 70% of carryover funds will be returned to
priority schools, 60% returned to schools in the “high” and “highest” SENI
quintiles, 50% returned to all other schools.”</blockquote>
<p>This is a major step forward, but the battle over funding for 2024-2025
is not over yet.</p>
<h2>Update Your Information with UTLA at Monroe</h2>
<p>It is important for UTLA at Monroe to have up-to-date contact information
for all of our members. Sometimes members change their phone numbers, and
sometimes the email address we have for you is one you have discontinued,
or rarely check, or have forgotten about. Particularly with email, we
generally have no way of knowing that our communications are not reaching
you.</p>
<p>If your information has changed, please reply to this email with any
updates and I will make sure they get into each part of Monroe UTLA’s
database.</p>`
}