module.exports = {
id:114,
title: 'The LA School Board Race & Monroe Educators (Part 1)',
date: '2024-01-13',
content: `<p>We won two important victories over the past year. Our solidarity strike in late March helped us get a good contract, and also built the combined power of LAUSD unions. This was evident in our second victory, where we won a good healthcare agreement in the face of resistance from the district&ndash;whereas the average public employee in the state of California pays ~$500 a month in healthcare premiums, UTLA remains one of the very few unions in the entire country without employee premiums.</p>
<h2>What We Need You to Do Now&ndash;the March 5th School Board Election</h2><p>UTLA has endorsed candidates in School Board Districts 1,3, and 5 (we do not have an endorsement in 7). Most Monroe teachers live in or very close to District 3, which is to the north, south and west of Monroe, though Monroe itself is in District 6 with Kelly Gonez. Our main task is helping<a href="https://boardmemberscott.org/"> Scott Schmerelson</a>, a longtime friend of teachers and teachers unions, win District 3.</p>
<p>As happened in 2020,<a href="https://boardmemberscott.org/"> Schmerelson</a> is up against a well-funded, charter backed candidate. Polls show that teachers retain enormous credibility with the general public&ndash;we must counter the charter lobby and their candidate by talking with the voters ourselves.</p>
<h2>Monroe Teachers Will be Precinct Walking&ndash;Here's How to Sign Up</h2>
<p>This week your Chapter Action Team leaders will be asking you to sign up for precinct walking. We&rsquo;re focusing on Sunday, February 4th, the week mail-in ballots go out, and Sunday, March 3rd, two days before the March 5th primary election. We will supply you with all needed materials plus snacks and water&ndash;what we're looking for from you is a four-hour commitment (11 AM - 3 PM).</p>
<p>If neither of these two days is convenient, members will also be doing precinct walking next Saturday, January 20th and Saturday February 17th, both from 10 AM to 2 PM.</p>
<h2>Our Opponent in District 3</h2>
<p><strong><br /></strong>Schmerelson is up against a formidable opponent, a charismatic younger educator named<a href="https://www.chang4change.org/?gclid=Cj0KCQiAhomtBhDgARIsABcaYynDIDfUMo587td1r2BGENov6RhiHOyXyv4i-CRe3MlapgEuiIoKcjYaAhG1EALw_wcB"> Daniel Chang</a>. Chang, who is also the chairman of the board of directors of Valley Charter Schools, says on his website that during his time at the charter school network Green Dot he &ldquo;led the opening of 17 high performing charter high schools throughout Los Angeles.&rdquo;&nbsp;</p>
<p>A more accurate description would be &ldquo;Chang led the opening of 17 white-collar sweatshops where underpaid, overworked young teachers labored for years until going to LAUSD where they would earn a decent wage, have their time respected, and be treated correctly.&rdquo;</p>
<p>Chris Rosas, our former principal, used to call charter schools Monroe&rsquo;s &ldquo;minor league system.&rdquo; Young teachers go there, work really hard, make their rookie mistakes, grow weary of being treated badly, and then come to LAUSD. There are a bunch of charter refugees here at Monroe right now, and they&rsquo;ve told us plenty of horror stories about the charter schools they left.</p>
<h2>Chang and Schmerelson in the 2019 strike </h2></p>
<p>The sharply contrasting actions of Schmerelson and Chang during our decisive 2019 teacher strike say much about each candidate. According to UTLA, Daniel Chang crossed the picket line, letting his colleagues down and contributing to the potential weakening of our bargaining position. </p>
<p>Schmerelson, by contrast, played an important role in the 2019 strike&ndash;he was the first board member to publicly break with Austin Beutner and come out in favor of UTLA. This set off something of a school board desertion from Beutner.</p>
<h2>Schmerelson in 2019: &lsquo;I Stand with Teachers Because Today They Are Standing for What&rsquo;s Best for Students&rsquo;</h2>
<p>On the third day of the strike Schmerelson released a statement called &ldquo;Where Do I Stand?&rdquo; He wrote:</p>
<p>&ldquo;I can no longer allow Mr. Beutner to speak for me or to suggest that the massive public relations, and often misinformation, campaign that he is waging represents my views about the current teachers strike. We need to end the strike&hellip;</p>
<p>&ldquo;My constituents, and parents throughout the District, are demanding to know just where I stand. I will tell you: As a retired LAUSD teacher, counselor, and principal, I dedicated my life and career serving LAUSD kids. I continue to stand with the kids. For me, this means that I Stand with Teachers because today they are standing for what&rsquo;s best for students&hellip;&rdquo; To learn more,<a href="https://www.counterpunch.org/2019/01/17/la-teachers-strike-black-smoke-pouring-out-of-lausd-headquarters/"> click here</a>.</p>
<h2>Chang in the 2023 Strike</h2></p>
<p>According to UTLA, Daniel Chang was not on the picket lines with his colleagues in 2023. It is not known whether he crossed and went in to work.</p>
<h2>An Important point about this Election</h2>
<p>Monroe Chapter Action Team leaders met on Thursday and Matthew Mize, a social studies teacher who came to Monroe two years ago, made an important point. Mize explained that when the average person votes in a school board election, if they don't know the candidates, they'll vote for the educator.</p>
<p>This often works in UTLA's favor but not this time&ndash;Chang, however politically misguided he may be, is an LAUSD teacher and the ballot will reflect this</p>
<p>One of the (many) reasons UTLA members need to get out and talk to voters is to make sure they understand the distinction between Schmerelson&ndash;a longtime educator-turned LA School Board member&ndash;and Chang.</p>
<h2>Our Opponents&rsquo; 2-Track Approach</h2>
<p>Chang so far has had an upbeat message, so it's assumed they'll use a two-track approach: Chang will run a positive campaign without personal attacks, while the California Charter School Association and its allies and billionaire accomplices, under the name of a fake, Astroturf front group, will spend a small fortune on mailings attacking Schmerelson.&nbsp;</p>
<p>In November 2020, the<a href="https://www.latimes.com/california/story/2020-11-04/lausd-school-board-results-pro-charter-school-tilt"> <em>Los Angeles Times </em>noted</a> that &ldquo;Supporters of charters, backed by a relatively small number of wealthy donors, vastly outspent unions &mdash; mostly the teachers union &mdash; by more than 5 to 1 in District 3&hellip;&rdquo;</p>
<h2>The Charter Lobby&rsquo;s Backhanded Tribute to Schmerelson</h2>
<p>They say &ldquo;When you're taking flak you know you're over the target&rdquo;. Schmerelson is important&ndash;we know because in 2020, the charter lobby launched an expensive, wide-ranging smear campaign against him.</p>
<p>Speaking as a US Government teacher who has spent much time perusing campaign ads, both current and historical, it would be hard for me to find anything in the modern era as misleading and dishonest as these anti-Schmerelson ads. The ads portrayed him as greedy and evil because some investment he really had no connection to was in some way connected to vaping.</p>
<p>We will have more information about the School Board District 3 race and others in upcoming mailings. </p>`
}