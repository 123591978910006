import React, {useEffect } from 'react'
import {Link} from "react-router-dom";
import Container from 'react-bootstrap/Container';

const Updates = props => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <h1 style={{ marginTop: '3.5rem', fontSize: '1.75rem'}}>Recent Updates</h1>
      <ul id="recentUpdateList">
        {props
          .recentUpdates
          .map(function (update) {
            return <li key={update.id}>
              <Link to={'/update/' + update.id}>
                <span
                  dangerouslySetInnerHTML={{
                  __html: update.title
                }}></span>
              </Link><br/> <span className="homeUpdate">update #{update.id} &middot; {props.formatDate(update.date)}</span></li>
          })
}
      </ul>
    </Container>
  )
}

export default Updates