module.exports = {
id:111,
title: 'What We Learned at the UTLA Leadership Conference<br/>(Part 1)',
date: '2023-08-08',
content: `<p>Along with Monroe UTLA Chapter Chair Lourdes Quevedo, Vice Chair Jacqueline Fonseca, and newly-elected Vice Chair Geronimo Mendoza, I attended the UTLA Leadership Conference in downtown Los Angeles this weekend. Below is some of what we learned about UTLA&rsquo;s goals for the 2023-2024 school year.&nbsp;</p>
<p><h2>Our Next Battle&ndash;Healthcare</h2></p>
<p>UTLA&rsquo;s first priority for the 2023-2024 school year is negotiating the healthcare agreement between LAUSD and the different unions who work in LAUSD, including UTLA and SEIU Local 99. The current agreement expires December 31st.&nbsp;</p>
<p>As some of you are aware, UTLA is one of the very few unions in the entire country to have won healthcare without employee premiums for all of its members. The average public employee in the state of California pays ~$500 a month in healthcare premiums. In LAUSD we pay nothing.</p>
<p><h2>UTLA&rsquo;s Healthcare Goals</h2></p>
<p>UTLA's goals in the negotiations are:</p>
<ul>
<li>A three-year renewal&nbsp;</li>
<li>No employee-paid premiums</li>
<li>A $100 million district reserve fund to cover any increases in healthcare costs that might occur over the life of the contract&nbsp;</li>
</ul>
<p>One of the problems we face is that at current funding levels LAUSD will fall short by 2025. If this happens, the district can deduct the premiums from our paychecks. In these negotiations, we need to make sure this does not happen.</p>
<p>On a positive note, it is undoubtedly true that the joint SEIU-UTLA labor action in March, in which we honored SEIU picket lines, serves as a show of force that strengthens our hand in these healthcare negotiations.&nbsp;</p>
<p>Negotiations will be proceeding this fall, we will keep you informed.&nbsp;</p>
<p><h2>Carvalho &amp; Healthcare</h2></p>
<p>During union negotiations last semester, LAUSD superintendent Alberto Carvalho, to his credit, declared, &ldquo;Healthcare is a human right.&rdquo; Of course, how he interprets this human right in the upcoming negotiations is a different question. In the past, UTLA has successfully fought LAUSD attempts to cut our healthcare. </p>
<p><h2>Previous Healthcare Negotiations &amp; Fights</h2></p>
<p>Both in 2017 and in 2020 UTLA beat back LAUSD attempts to cut our health care. In 2017 LAUSD even put a number on how much they&rsquo;d like to cut&mdash;44%. They wanted to:</p>
<ul>
<li>Make us pay $300/$400/$500 a month towards our healthcare benefits.</li>
<li>Eliminate dependent healthcare&ndash;you&rsquo;d get free healthcare but would have to pay for it out of pocket for your spouse and your children.</li>
<li>Reduce or eliminate healthcare for LAUSD retirees</li>
</ul>
<p>Also, as we've mentioned before, on the eve of our 2019 strike, the raise being offered to us by LAUSD was conditioned on UTLA accepting cuts to new employees&rsquo; healthcare benefits. We refused, and the strike forced LAUSD to drop this demand. </p>
<p><h2>Monroe's Lourdes Quevedo Honored at Conference </h2></p> <p>Monroe UTLA Chapter Chair Lourdes Quevedo was honored as one of UTLA&rsquo;s &ldquo;unsung heroes&rdquo; at the conference. She is certainly deserving of such recognition.&nbsp;</p> <p>My prediction is that next year Monroe UTLA Vice Chair Jacqueline Fonseca will be similarly honored.</p> <p><h2>UTLA &amp; the Dramatic Decline in LAUSD enrollment</h2></p>
<p>Another of UTLA's announced priorities for the 2023 to 2024 school year is housing. Before you ask, &ldquo;What does that have to do with us?&rdquo;, consider this&ndash;between 2018 and 2022 LAUSD lost roughly 78,000 students, a decline of 16.6% (1/6th) in our enrollment.&nbsp;</p>
<p>While obviously what we can do about this is limited, it&rsquo;s an issue we can&rsquo;t ignore. Also, this time we&rsquo;re on the same side as Carvalho and LAUSD, which is a nice change.&nbsp;</p>
<p><h2>What&rsquo;s Causing this Decline?</h2></p>
<p>Following the rule that whatever negative thing happens in our schools, it&rsquo;s always the fault of teachers and teachers unions, after the March strike prominent libertarian journalist Matt Welch called LAUSD&rsquo;s enrollment drop &ldquo;an advertisement against public sector unionism.&rdquo; I had thought all those students we've had who leave Monroe because they're moving to Modesto or Bakersfield or Merced or Palmdale were trying to escape high rents, not us.&nbsp;</p>
<p>At one of the conference&rsquo;s general sessions, Peter Dreier, founder and chair of the Urban &amp; Environmental Policy Department at Occidental College, gave some statistics outlining the scale of the problem:</p>
<ul>
<li>78% of Los Angeles rental units rent for $2,000 or more.</li>
<li>The average rent for an apartment in Los Angeles is $2,781 a month</li>
<li>63% of the people who live in Los Angeles are renters, not owners.</li>
<li>Between 2015 and 2023, home prices in Los Angeles rose from $516,000 to $916,000.</li>
<li>Los Angeles has a higher percentage of cost burden for renters than any other major American city.</li>
<li>Almost half of all Los Angeles households spend more than a third of their income on rent.</li>
</ul>
<p>He identified several causes of the problem:&nbsp;</p>
<ul>
<li>Zoning laws make it difficult to build apartments.</li>
<li>Gentrification</li>
<li>Corporate landlords</li>
<li>The Costa-Hawkins Act of 1995, which forbade California cities from instituting rent control. (Costa-Hawkins was only prospective, so rent control in places like Los Angeles, Santa Monica, etc, was de facto grandfathered in. )</li>
<li>The Ellis Act, which has led to thousands of apartment units being taken off the rental market.</li>
</ul>
<p><h2>What&rsquo;s the Solution?</h2></p>
<p>Dreier&rsquo;s solutions include:</p>
<ul>
<li>Use public land (including empty LAUSD land) to build affordable housing. This is a proposal that UTLA has taken up and is advocating.</li>
<li>Inclusionary zoning for multi-family housing, Meaning that when an apartment is built, it cannot all be units at market rents&ndash;some units must be at what are considered affordable rents</li>
<li>Repealing Costa-Hawkins. You may recall that there was an attempt to make a partial repeal via Proposition 21 in the 2020 election. While Proposition 21 was originally leading in the polls, a well-financed campaign against it ended up killing it. By the way, Proposition 21 did not mandate anything&ndash;it simply said that cities can adopt rent control if they think it is what's best for them.</li>
<li>Get the LA City Council to enforce and strengthen tenant protection laws</li>
</ul>
<p><h2>What Are Teachers Being Asked to Do?</h2></p>
<p>Following Dreier was <a href="https://www.unitehere11.org/susan-minato-black-lives-matter/">Susan Minato</a>, co-president of UNITE HERE Local 11, which represents over 32,000 workers in hotels, restaurants, airports, and others.</p>
<p>Minato, a powerful and charismatic speaker, said a major driver of high rents in Los Angeles is BlackRock, one of the largest asset management/investment firm management companies in the world. She said BlackRock owns 65% of all the units in the Los Angeles rental market, a shockingly high figure.</p>
<p>One of the things she proposes involves UTLA, and her idea is one you'll probably be hearing about in coming months.</p>
<p>As you know, our pensions are held through the State Teachers Retirement System, which has about $330 billion in total holdings. Of that, $8 billion dollars is in Black Rock stock. She calls upon UTLA and other teachers unions to push STRS to divest from Blackrock.</p>
<p><h2>The Problem with Consumer Boycotts &amp; Divestments</h2></p>
<p>My view is that while I have nothing against such a divestment, as a general rule, I have a low opinion of the efficacy of consumer boycotts &amp; divestments. (Yes, I&rsquo;m aware of the success of the reactionary Bud Light boycott). They generally accomplish little. Moreover, they can unwittingly serve as a diversion away from effective action.</p>
<p>The worst consumer boycotts are the ones done during labor battles that are put forward by labor leaders as an alternative to strikes or other collective action. The most egregious of these was during the 1981 air traffic controllers strike.</p>
<p>During that strike Lane Kirkland, president of the AFL-CIO, urged a consumer boycott of all airplane flights in place of calling out airline pilots, stewardesses, ground transportation, and other airport workers in a solidarity strike to shut down the airports and force the government to negotiate meaningfully with PATCO, the air traffic controllers union. (I would add that the air traffic controllers strike was mostly about their highly stressful working conditions, not salaries.) </p>
<p>In the end Ronald Reagan fired and permanently banned 11,345 striking air traffic controllers, crushing the union and setting off an era of corporate union busting.&nbsp;</p>
<p>BlackRock is not going to lower rents because we've divested from them, nor are they likely to change any of their other practices. Symbols can be important, and it&rsquo;s possible that STRS divesting would be symbolically important. But let's not be under the illusion that it's going to change the way BlackRock does business.</p>
<p><h2>Who Receives These Updates?</h2><p>When an update only concerns information specific to Monroe UTLA, it goes only to Monroe UTLA members. When it only concerns information general to UTLA and LAUSD, it goes out both to Monroe members and to some of the other chapter chairs and vice-chairs in the San Fernando Valley. When it concerns information both related to Monroe and to UTLA/LAUSD in general, I send the full one out to Monroe members, and the non-Monroe information out to other San Fernando Valley chapter chairs and vice-chairs.</p>
<p>Chapter chairs and vice-chairs often exchange information that helps our members. In recent months Valley East Area Chair Scott Mandel has been particularly helpful in this manner.</p>`
}