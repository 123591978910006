import React from 'react'
import Navbar from 'react-bootstrap/Navbar'

import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";

const MainNavBar = ({ noLink = false }) => {
    return (
        <div style={{
            backgroundColor: "#8b0000"
        }}>
            <header>
                <Navbar id="navBar" fixed="top">
                    <Container>
                        <Navbar.Brand>

                            {noLink ? <span style={{ color: '#fff' }}>Monroe UTLA Updates</span> : <Link
                                to="/"
                                style={{
                                    color: "#fff"
                                }}>Monroe UTLA Updates</Link>}
                        </Navbar.Brand>
                    </Container>
                </Navbar>

            </header>
        </div>
    )
}

export default MainNavBar