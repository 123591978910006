module.exports = {
id:122,
title: 'What We Learned at the UTLA Leadership Conference (Part 2)',
date: '2024-08-07',
content: `

<p>Along with Monroe UTLA Chapter Chair Lourdes Quevedo and Vice Chair Jacqueline Fonseca, I attended the UTLA Leadership Conference in downtown Los Angeles this weekend. Below is some of what we learned.</p>
<h2>LAUSD&rsquo;s New Cellphone Ban</h2>
<p>Banning phones is the idea of the moment among politicians, educators and education advocates. For example, California Gov. Gavin Newsom pledges he will sign a smartphone ban into law later this summer. The Los Angeles Unified School District Board recently voted to ban phones starting in January.</p>
<p>LAUSD will prohibit students from using their phones during school hours, including nutrition and lunch.<br /><br /></p>
<p>Like many teachers, I certainly would not miss having to regulate cell phones, however, there are problems with banning them that would have to be overcome. I wrote about this recently for <em>FoxNews.com</em> in <a href="https://www.foxnews.com/opinion/classroom-teachers-view-cellphone-ban-likely-more-trouble-than-its-worth">Cellphone ban likely more trouble than it's worth</a> (7/20/24).</p>
<h2>Issues for Educators &amp; UTLA</h2>
<p>UTLA has not made a public statement about LAUSD&rsquo;s cellphone ban, but I spoke with UTLA president Cecily Myart-Cruz about it at the conference and there are several things that are of concern to LAUSD educators and UTLA:</p>
<ul>
<li>UTLA was never consulted before the ban was passed by the LAUSD school board.</li>
<li>As of last Saturday, UTLA still had not been consulted about the ban.</li>
<li>However the ban ends up being implemented, it's going to take a lot of man hours to operate and manage.</li>
<li>Each school will be deciding how they will implement the phone ban.</li>
</ul>
<p>On a surface level, giving individual schools the power to decide how they want to implement their phone bans sounds good, however, there are problems with this.</p>
<p>If the district and the union had come to an agreement as to how the ban will be implemented in all schools, or how it will be implemented in each type of school (elementary schools, junior high schools, high schools, continuation schools, etc.), it would be much easier for UTLA to enforce the agreement at the school sites. As it stands now, the field is wide open for a cascade of abuses large and small.</p>
<p>Administrators at some schools may interpret their phone ban plan in a manner different than the UTLA leadership at that school site does. If the ban at a school is ill-conceived or poorly implemented, it could greatly inconvenience teachers and/or antagonize parents and students. Some admins might use the ban to push forward their plans or goals on unrelated issues. If there are a large number of disputes, it might take a long time to adjudicate and resolve them.</p>
<h2>Who is Going to Operate the Ban?</h2>
<p>Operating the ban is going to be very labor intensive&ndash;who is going to do that work? If they propose throwing the work onto teachers, who are already overworked, that's a major problem. The same goes for handing the task to SEIU workers.</p>
<p>From what we can see so far, I believe school administrators and administrative personnel should be the ones to handle it. However, for that to be done properly, the district would need to provide the administrative staff of each school with more man-hours, either by sending someone new or providing extra money to expand the hours of a current part-time employee. Perhaps this is the district's intent, but no word of it has come out.<br /><br /></p>
<h2>Could the Way LAUSD Implemented the 2021-2022 COVID Safety Precautions Demonstrate the Way Forward?</h2>
<p>When LAUSD instituted mandatory COVID testing and masking to allow us to return to in-person learning in August of 2021, I thought it was going to be a time grind and hassle for all involved. The testing was rocky at first and some teachers, myself included, complained about the wasted time.</p>
<p>Yet within a few weeks it was running efficiently, and testing usually ended up taking only 10 to 15 minutes. The administrators handled it well and disruptions to classes were very modest.</p>
<p>I praised the school district and the Monroe administrators for the way they handled this in <a href="https://calmatters.org/commentary/2022/01/schools-dealing-with-closures-could-learn-from-los-angeles-unified/">Schools dealing with closures could learn from Los Angeles Unified</a> (<em>MSN.com</em>, 1/14/22). I feel that, properly supported, most school administrations could do a similarly good job with a cell phone ban.</p>
<h2>Other Issues with the Ban</h2>
<p>Banning cell phones is understandably seen as a way to avoid some of the conflicts and fights among students that they often cause. However, most school districts&rsquo; internet already blocks social media sites such as Instagram and TikTok, as well as various websites. And, ban or no, social media wars can and will go on outside of school hours.</p>
<p>Parents are already speaking out against the phone ban &ndash; phones allow them to coordinate pickups and scheduling and to communicate with their kids in an emergency.</p>
<p>Students will not be happy about it, and we've all seen how tenaciously some of them resist restrictions on their phone use or access.</p>
<p>Moreover, students&rsquo; phone-based activities and distractions have already been migrating to their Apple Watches to evade our class rules limiting phones, a trend a ban will accelerate. From a teacher's perspective, the watches are much more difficult to police than the phones.</p>
<h2>No Silver Bullets</h2>
<p>In looking for solutions to problems in our schools, there's an unfortunate tendency to look for specific villains to blame &ndash; video games, smartphones, social media, and whatever else we lament about "kids today" &ndash; and to see addressing these as a silver bullet. In some ways the cell phone man is just the latest. <br /><br /></p>
<h2>UTLA Warns of the Danger Posed by Project 2025</h2>
<p>In UTLA president Cecily Myart-Cruz&rsquo;s opening statement she called attention to some of the enemies of teachers unions in general and UTLA in particular. She said that <a href="https://aftmichigan.org/wp-content/uploads/2017/10/what-is-project-veritas12.pdf">Project Veritas</a> has been trying to trap our members, and that the <a href="https://seiu73.org/resources/dont-be-fooled-by-freedom-foundation/">Freedom Foundation</a> has been trying to trick UTLA members into leaving the union.</p>
<p>Myart-Cruz focused on the threat posed to public education and teachers unions by Project 2025.</p>
<p>Journalist and retired teacher <a href="https://progressive.org/topics/peter-greene/">Peter Greene</a> has been warning teachers unions and the public over the far-right Heritage Foundation&rsquo;s Project 2025 for almost a year. In <a href="https://buckscountybeacon.com/2023/11/project-2025-wants-to-end-public-education-as-we-know-it/">Project 2025 Wants to End Public Education As We Know It</a>, Greene writes that Heritage has &ldquo;worked to push critical race theory bans, praised Florida&rsquo;s dismantling of public education, and repeatedly argued for education funding to be voucherized.&rdquo;</p>
<p>The education chapter of Project 2025 was written by Lindsey Burke, chief of the Heritage Center&rsquo;s Center for Education Policy. According to Greene, Burke believes &ldquo;Elementary and secondary education policy should follow the path outlined by Milton Friedman in 1955&hellip;simply, vouchers. The government would have no role except perhaps to certify a certain minimum level of quality among providers&hellip;the government would hand over a stack of taxpayer dollars to parents who would then spend these at the vendor of their choosing, be they non-profit, for-profit, religious, or secular.&rdquo;</p>
<p>Re: vouchers, Greene says, &ldquo;the reliable data on vouchers&hellip;is that vouchers produce worse outcomes. If tests are what you value as a measure, then vouchers fail. Nor has there been any conclusive evidence that charters produce consistently better results. A recent study that was touted as showing charter superiority turns out to be full of holes.&rdquo;</p>
<p>Greene says, &ldquo;...in broad introductory terms, Burke calls for federal spending on education to be turned to block-grants given to states without &lsquo;strings&rsquo; (aka regulations).&rdquo;</p>
<p>Of particular interest to Monroe High School is that, according to Greene, Project 2025 backers believe &ldquo;Title I funding, which provides support for low-income districts, should just be handed over as state grants that are &lsquo;no-strings-attached,&rsquo; i.e. taxpayer-funded grants with no regulation or oversight. IDEA funding, created to help support students with special needs, should also be converted to unregulated block grants.&rdquo;</p>
<p>He explains that Burke denounces what she calls &ldquo;special interest groups like the National Education Association (NEA), American Federation of Teachers (AFT), and the higher education lobby&rdquo; and pursues &ldquo;the same idea that Heritage has pushed for decades&mdash;the Department of Education should be abolished&hellip;The federal government should confine its involvement in education policy to that of a statistics-gathering agency that disseminates information to the states.&rdquo;</p>
<p>Democratic presidential candidate Kamala Harris opposes Project 2025, and Republican presidential candidate Donald Trump has been distancing himself from it. Nonetheless, it remains a popular idea in conservative circles, including in Congress. </p>`
}