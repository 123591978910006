module.exports = {
id:126,
title: 'Chang Denounces UTLA Activism but He Doth Protest Too Much...',
date: '2024-09-30',
content: `<p>This coming weekend we will be precinct walking to help <a href="https://boardmemberscott.org/">Scott Schmerelson</a>, a longtime friend and ally of teachers and teachers unions, win LAUSD School Board District 3. Our schedule is:</p>
<ul>
<li>Saturday October 5: 10 AM to 2 PM</li>
<li>Sunday October 6: 11 AM to 3 PM</li>
</ul>
<p>To sign up, simply reply to this email and tell us which date(s) you'd like to walk.</p>

<p>On October 7th, <a href="https://www.sos.ca.gov/elections/upcoming-elections/general-election-nov-5-2024">California counties begin</a> mailing out vote-by-mail ballots. All California active registered voters will receive a vote-by-mail ballot. Ballot drop-off locations open on October 8, 2024.</p>
<p>We will also be walking the 19th and the 20th:</p>
<ul>
<li>Saturday October 19: 10 AM to 2 PM</li>
<li>Saturday October 20: 11 AM to 3 PM</li>
</ul>
<p>We will supply you with all needed materials to precinct walk, plus snacks and water. Thanks to those of you who have already signed up to precinct walk.</p>
<h2>&lsquo;Teachers Union Smear Campaign&rsquo;</h2>

<p>In a recent email titled &ldquo;Here come the lies&rdquo;, Chang complains of a UTLA &ldquo;smear campaign&rdquo;, writing &ldquo;the status quo is filling voters' mailboxes with lies.&rdquo; </p>

<p>Below we look a little closer at some of Chang&rsquo;s complaints.</p>

<h2>Chang Claims UTLA Is &lsquo;Pushing&rsquo; a &lsquo;False Narrative&rsquo; about &lsquo;Billionaires&rsquo;</h2>
<p>Chang claims UTLA is &ldquo;pushing the false narrative of &lsquo;billionaires taking over public schools&rsquo; during this campaign. Yet as of September 25 <a href="https://laist.com/news/politics/2024-election-california-general-los-angeles-county-lausd-school-board-district-3">Chang has received</a> at least $4,244,779 in outside contributions, most of it from anti-union/pro-charter businessman <a href="https://capitalandmain.com/bill-bloomfield-the-rainmaker-1101">Bill Bloomfield</a>. This includes <a href="https://drive.google.com/file/d/1AwSvh6k9HkndoMA7r0dqhdsCdNEpgIhN/view?usp=sharing">$500K from Bloomfield</a> on September 10 and another<a href="https://drive.google.com/file/d/16Pp-5NJOgeNC9rtkAmOWyfAQeb0nMzCa/view?usp=sharing"> $225K from Bloomfield</a> just a few days ago, on September 25.</p>

<p>So we have a small number of extremely wealthy people donating tremendous amounts of cash to Chang so he can help create a pro-charter/anti-union majority on the Los Angeles School Board. I'm not sure what is &ldquo;false&rdquo; about UTLA&rsquo;s narrative.</p>

<p>And make no mistake&ndash;over the past six years UTLA has emerged as the nation&rsquo;s strongest teachers union and one of its strongest labor unions. This campaign is targeted at <em>us</em>, and if Chang wins, his backers will rightly see it as a defeat for teachers unions and for labor.</p>

<h2>What about the Campaign Contributions Schmerelson Gets?</h2>

<p>To be fair, Chang would probably note that Scott Schmerelson has also received campaign contributions, mostly from UTLA. However:</p>

<ul>
<li>There's a huge distinction between an organization of teachers (as well as teachers themselves as individuals) supporting a school board member, as opposed to big money coming from a handful of wealthy anti-union donors with no direct stake in LAUSD</li>
<li>Chang is getting <em>far</em> more money from his wealthy buddies than Schmerelson is getting from UTLA.</li>
</ul>
<h2>Chang Claims UTLA Has Turned &lsquo;Once Friendly' Colleagues Against Him</h2>
<p>Chang writes that because of UTLA&rsquo;s &ldquo;smear campaign&rdquo;, at James Madison Middle School where he teaches, &ldquo;colleagues who were once friendly won&rsquo;t even say hello.&rdquo; As Lourdes Quevedo noted during our UTLA meeting last week, the teachers at Madison have endorsed Scott Schmerelson, which says a lot about how they feel about Chang&rsquo;s candidacy.</p>
<p>But UTLA has criticized Chang <em>politically</em>, not personally. I wish Chang no ill will personally, and since he's concerned about his campus social life, I have a few suggestions which could help:</p>
<h2>Suggestion #1 for Dan Chang: Don't Cross Your Colleagues&rsquo; Picket Lines</h2>
<p>Mr. Chang, you undermined and betrayed your colleagues by crossing their picket lines during both the 2019 and 2023 teacher strikes. This wasn't simply a personal choice, it was a political act. Over 30,000 UTLA members sacrificed to fight for better schools, better learning conditions for our students, better working conditions for us, and to win the pay and benefits we deserve. Your actions weakened us, yet you benefit from the sacrifices we made. I'm not surprised that your colleagues are not happy with you.</p>
<h2>Suggestion #2 for Dan Chang: Stop Aiding the Forces Hurting Your Colleagues</h2>
<p>Mr Chang, you represent anti-union, anti-public school forces who want to undermine the employment and seniority protections LAUSD teachers have worked hard to earn. I'm not surprised that your colleagues are not happy with you.</p>
<h2>Suggestion #3 for Dan Chang: Stop Blaming Your Colleagues for LAUSD&rsquo;s Shortcomings</h2>
<p>Mr. Chang, the overwhelming majority of LAUSD students come from low-income, minority families whose parents never had the opportunity to get a college education. LAUSD&rsquo;s low test scores are a direct reflection of this, yet the forces you represent refuse to look at these test scores in their obvious context, and instead choose to blame and scapegoat hardworking teachers.</p>
<h2>Chang Claims Teachers Are the &lsquo;Status Quo&rsquo; Which His Campaign &lsquo;Threatens&rsquo;</h2>
<p>Chang writes &ldquo;the reason the status quo is doubling down is because we are making a real impact, and that threatens them.&rdquo; Again, this is reflective of the pro charter/anti-union ideology so often promoted by charter advocates via the <em>Wall Street Journal</em>, <em>Fox News</em>, conservative talk radio, and by the GOP.</p>
<p>In these critics&rsquo; view, teachers are overpaid, shiftless, and pampered, and we feel &ldquo;threatened&rdquo; whenever a truth telling freedom fighter like Chang threatens our cushy positions.</p>
<h2>To Be Fair to Chang&hellip;</h2>
<p>Those of us who live in Board District 3&ndash;the area North, Northwest, West, South and Southwest of Monroe High School&ndash;have been inundated with Chang's mailers. (A couple of Monroe teachers have asked me whether they live in District 3 or not, and all I can say is these Chang mailers are so ubiquitous that if you lived in District 3, you would know.)</p>
<p>These mailers are full color and printed on good card stock&ndash;Chang's high-dollar backers seem to be getting their money's worth.</p>
<p>In 2020, outside, charter-backed organizations spent a fortune on expensive mailers trashing Schmerelson with scurrilous lies. But it is important to note that Chang&rsquo;s mailers, while often lacking in specifics, have not contained personal attacks on Schmerelson.</p>
<h2>To learn more about the Chang versus Scott Schmerelson Electoral Contest&hellip;</h2>
<p>&hellip;see some of our previous Monroe UTLA Updates below:</p>
<ul>
<li><a href="/update/125">The Battle Against Pro-Charter Candidate Dan Chang<br /></a>update #125 &middot; 09/23/2024</li>
<li><a href="/update/124">What We Need You to Do for the Upcoming School Board Election<br /></a>update #124 &middot; 09/02/2024</li>
<li><a href="/update/117">The Charter/Chang Offensive Against Public Education<br /></a>update #117 &middot; 01/28/2024</li>
<li><a href="/update/116">The Hidden Forces Behind Chang&rsquo;s Expensive New Mailers<br /></a>update #116 &middot; 01/21/2024</li>
<li><a href="/update/115">The LA School Board Race &amp; Monroe Educators (Part 2)<br /></a>update #115 &middot; 01/15/2024</li>

<li><a href="/update/114">The LA School Board Race &amp; Monroe Educators (Part 1)<br /></a>update #114 &middot; 01/13/2024</li>
</ul>
`
}