import React, {useEffect } from 'react'
import {Link} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import MainNavBar from './MainNavBar';

const Updates = props => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <><MainNavBar /><Container>
      <main>
        <ul id="updateList">
          {props
            .updates
            .map(function (update) {
              return <li key={update.id}>
                <Link to={'/update/' + update.id}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: update.title
                    }}></span>
                </Link><br /> <span className="homeUpdate">update #{update.id} &middot; {props.formatDate(update.date)}</span></li>;
            })}
        </ul>
      </main>
    </Container></>
  )
}

export default Updates