module.exports = {
id:113,
title: 'What We Learned at the UTLA Leadership Conference<br/>(Part 3)',
date: '2023-08-10',
content: ` <p>Along with Monroe UTLA Chapter Chair Lourdes Quevedo, Vice Chair
      Jacqueline Fonseca, and newly-elected Vice Chair Geronimo Mendoza, I
      attended the UTLA Leadership Conference in downtown Los Angeles this
      weekend. Below is some of what happened at the conference and what we
      learned about UTLA’s goals for the 2023-2024 school year.
    </p>
    <h2>An Unfortunate Oversight</h2>
    <p>Of the many deserving people who were honored at the leadership
      conference, including a couple of former UTLA presidents who were in
      attendance, one person's name was not mentioned–Alex Caputo-Pearl,
      president of UTLA from 2014 to 2020 and our leader during the decisive
      January 2019 strike. 
    </p>
    <p>Caputo-Pearl had an out-of-town emergency, so he missed Friday and
      Saturday but he was in attendance on Sunday. Given his monumental
      contributions to UTLA, it would have been nice for him to have been
      recognized then. It’s an unfortunate oversight that he wasn’t.
    </p>
    <p>And yes, of course I realize that the 2019 victory was the collective
      work of tens of thousands of people and that Caputo-Pearl was one of many.
      He is the first one to emphasize that this was a team win. 
    </p>
    <p>But tell me this–what if we had <em>lost</em> the strike? What if our
      picket lines had crumbled, as I feared they might? What if we were
      defeated and forced to sign a humiliating contract which set us back for
      years? Would it have been a team effort then? Would we all have accepted
      the blame for the loss? 
    </p>
    <p>They say victory has a thousand fathers yet defeat is an orphan. Had we
      lost, Alex Caputo-Pearl is the one who would have been held principally
      responsible for our defeat. Fortunately, under his leadership, we won.
      Hopefully next year he will be able to be with us for the whole conference
      and be properly recognized, and this unfortunate oversight can be
      corrected. 
    </p>
    <h2>Other UTLA Priorities for 2023-2024</h2>
    <p>In the previous updates we discussed three of UTLA’s priorities–securing
      our health care, the upcoming school board elections, and building on the
      common good for our students, particularly in housing. Another is
      enforcing our contract in our classrooms and chapters, which we’ll be
      discussing in future updates.
    </p>
    <h2>‘Defending our communities and public education from internal and
      external attack’</h2>
    <p>The other UTLA priority is defending against recent large-scale attacks
      on Ethnic Studies and on LGBTQ educators, students, and LGBTQ-themed
      school literature
    </p>
    <h2> Ethnic Studies </h2>
    <p>Ethnic Studies has been under considerable attack for several years, even
      in comparatively friendly California. Recently I spoke with a young LAUSD
      Ethnic Studies teacher who told me that at one school she had endured
      withering attacks from a parent simply because she taught Ethnic Studies.</p>
    <p>Moreover, Ethnic Studies teachers are in a relatively vulnerable position
      in relation to LAUSD because they tend to be younger and probationary, as
      opposed to older, more established teachers with permanent status. UTLA
      says there's not a lot they can do about it because probationary status is
      governed by state law, not the LAUSD-UTLA contract.
    </p>
    <p>The UTLA conference had a workshop about Ethnic Studies and I learned
      some interesting things. 
    </p>
    <p>For one, LAUSD has actually had Ethnic Studies for over 50 years. The
      difference is that it was always an elective that schools could offer if
      they wanted to. With the passage of AB 101 in 2021, it became a statewide
      graduation requirement.</p>
    <p>Other things we learned included:</p>
    <ul>
      <li>The use of Latinx is controversial, both among Latinos and
        non-Latinos. While many assume that the origin of Latinx was in American
        liberal academia, the presenter said it's actually an Afro-Brazilian
        feminist term that ended up gaining traction. Spanish speakers,
        including some at the conference, complain that Latinx does not really
        work in Spanish. The presenter acknowledged this, but said that it
        actually does work in Portuguese.</li>
      <li>Ethnic Studies is not a class about ethnic minorities but is instead
        an attempt to speak through their perspective. The presenter said it is
        fine for white teachers to teach it, but that anybody teaching it has to
        try to do so from the perspective of the ethnic minority being studied.</li>
      <li>The presenters noted that for all the criticism of Ethnic Studies as
        allegedly a waste of valuable class time, K-12 students will be in
        school for 12,840 hours of classes, including 4,320 in high school. Out
        of all of this, ethnic studies is only 90 hours–one semester, .07% of a
        student's overall class time.</li>
      <li>Some of what has fueled the criticism of the Ethnic Studies curriculum
        in California is the inclusion of Palestinian perspectives, with less
        discussion of Israeli and Jewish perspectives than is customary.
        Defenders of the California Ethnic Studies curriculum argue that since
        both the US History and World History curriculum discuss Zionism, the
        Holocaust, and the founding of Israel a considerable amount, it's not
        unreasonable for an Ethnic Studies class to focus more on the
        Palestinian perspective.</li>
      <li>The instructors noted how Eurocentric and US-centric the California
        Subject Examinations for Teachers for Social Studies is, and they
        provided statistics about how many questions addressed different
        groups–Europeans, Native Americans, Africans, etc.</li>
    </ul>
    <h2>Attacks on LGBTQ Educators, Students, and LGBTQ-Themed School Literature</h2>
    <p>UTLA also said it’s important to fight off attacks on LGBTQ educators and
      students, as well as LGBTQ-themed school literature.The recent
      demonstrations at Saticoy Elementary and in Glendale were referenced
      several times. 
    </p>
    <p>There was also some discussion of the massive boycott of Bud Light over
      trans influencer Dylan Mulvaney, who in April promoted the beer in an
      Instagram video regarding the March Madness College basketball tournament.
    </p>
    <h2>Trump: ‘I talk about transgender, everyone goes crazy’</h2>
    <p>In his own way, former President Donald Trump actually told a remarkable
      truth about modern trans-bashing earlier this summer after a speech he
      gave to the North Carolina Republican Party convention. 
    </p>
    <p>Trump said if he’s elected he would “immediately sign a new executive
      order” to take federal funding away from schools that bring critical race
      theory and “transgender insanity” to children. His comments got a standing
      ovation, then <a href="https://twitter.com/atrupar/status/1667695593433571332?s=20">Trump
        noted</a>:</p>
    <p>“It’s amazing how strongly people feel about that. You see I’m
      talking about cutting taxes, people go like that. (A soft clap)...I talk
      about transgender, everyone goes crazy. Who would have thought? Five years
      ago, you didn’t know what the hell it was.”
    </p>
    <p>In other words, even Trump is surprised at the anti-trans tidal wave,
      though of course like any nimble politician, he's going to leverage it to
      his advantage…
      
    </p>
    <h2>DeSantis’ War on Trans Teachers &amp; Students, &amp; What Nobody
        Seems to Mention</h2>
    <p>The situation in Florida was repeatedly referenced at the UTLA
      conference. In <a href="https://slate.com/news-and-politics/2023/08/florida-ron-desantis-attacks-trans-students-teachers.html#:%7E:text=Ron%20DeSantis%27%20Attempt%20to%20Erase,Florida%20Schools%20Is%20Now%20Underway&amp;text=On%20Monday%2C%20the%20deputy%20general,for%20the%20upcoming%20academic%20year.">Ron
        DeSantis’ Attempt to Erase Trans People From Florida Schools Is Now
        Underway</a> (<em>Slate</em>, 8/8/23), <em>Slate</em> senior writer
      Mark Joseph Stern explains:
    </p>
    <p>“On Monday, the deputy general counsel of Florida’s Orange County Public
      Schools system sent an email to principals announcing new policies for the
      upcoming academic year. To implement recently enacted state laws, the
      email announced, the school district—which encompasses Orlando and serves
      more than 200,000 students—would be required to target transgender
      teachers, staff, and students for discriminatory treatment. 
    </p>
    <p>“Transgender teachers may no longer use their preferred pronouns or
      titles (such as Mr. or Ms.) in class; they must instead use the pronoun
      and title that corresponds to their “biological sex,” as assigned at
      birth. 
    </p>
    <p>“Transgender teachers, staff, and students may no longer use the bathroom
      that aligns with their gender identity at school. 
    </p>
    <p>“And transgender ‘visitors’—including parents, guardians, and students
      from other districts or states—will face criminal prosecution if they use
      the bathroom that fits their gender identity on school premises…
    </p>
    <p>“H.B. 1069 and H.B. 1521, the two laws in question, are part of DeSantis’
      broader campaign against LGBTQ+ equality…H.B. 1069…compels teachers and
      staff to identify with the sex that appeared on their original birth
      certificate—so, for example, a transgender woman must use he/him pronouns
      and the title of ‘Mr.’ It also gives all teachers, staff, and students the
      right to misgender trans employees, and forbids teachers from asking for
      students’ preferred pronouns.
    </p>
    <p>“Orange County Public Schools will begin implementing these rules
      immediately. The district has also created a separate procedure for
      students who wish to use a name at school that is different from their
      legal name. From now on, parents must fill out a form granting teachers
      permission to use any such name in class. This policy applies to all
      students, not just transgender ones. So a student named Robert who wishes
      to go by Bob will now need a permission slip to do so. Teachers may never
      ask a student for their preferred pronouns; those who do may have their
      educator’s certificate revoked.”
    </p>
    <h2>Civil Rights Challenges</h2>
    <p>Stern notes, “These laws, as well as the guidance implementing them, are
      highly vulnerable to civil rights challenges—particularly those rooted in
      the defense of free expression and workplace equality. <em>Slate</em>
      spoke with lawyers for several different civil liberties groups on Monday
      who were considering legal action in the near future.”
    </p>
    <p>Obviously one hopes that these discriminatory laws and policies will die
      in the courts, and it's quite possible they will.
    </p>
    <p><h2>However, these laws targeting trans teachers and students are
        only possible because…</h2>
    <p>…teachers unions in Florida are weak. The piece <a href="https://prismreports.org/2023/08/10/florida-teachers-fight-save-union/">In
        Florida teachers fight to save their union from new restrictions</a>
      details some of their woes–right-to-work laws, Florida law, under which
      public-sector employees are prohibited from striking, and a new anti-union
      law which went into effect July 1. Katherine Demby explains:
    </p>
    <p>“Senate Bill 256, signed into law by Gov. Ron DeSantis in early May,
      prohibits the direct collection of dues through employee paychecks, a
      common practice for unions. The bill also raises the union density
      requirement for dues-paying members from 50% to 60% of all ‘bargaining
      unit members,’ putting pressure on the union to recruit more teachers
      while also successfully establishing a direct dues payment process.”
    </p>
    <p>Failing to meet a “density requirement” means the union is at risk of not
      being authorized to serve as the agent to collectively bargain for the
      employees of that district. And to show you who the law is aimed at, it
      applies to all public unions, except organizations that represent law
      enforcement, correctional officers, and firefighters.
    </p>
    <p>I was once a member of United Teachers of Dade and the relative weakness
      of the union and the generally anti-union environment were quite apparent.
      Can you imagine Sacramento or LAUSD trying to pull this nonsense on UTLA?
      
    </p>
    <p>The various articles covering these types of laws focus on the
      legislative debate, the battle in the courts, and the PR contests, but
      almost never mention that strong teachers unions have the power to shut
      down all this nonsense.&nbsp;</p>
    <h2>Christopher Rufo goes after LAUSD’s LGBTQ-friendly policies</h2>
    <p>Some of you may recall that last year activist/provocateur Christopher
      Rufo, whose attacks on Critical Race Theory energized the conservative
      “parents’ revolt” against public schools, <a href="https://www.foxnews.com/opinion/la-schools-adopt-radical-trans-affirming-agenda-attack-gender-binary">launched
        an assault</a> on what he calls the Los Angeles Unified School
      District’s “radical gender-theory curriculum.” Major conservative media
      outlets highlighted Rufo’s attempted takedown of LAUSD’s new <a href="https://achieve.lausd.net/cms/lib/CA01000043/Centricity/domain/156/pdfs/sogie/Queer%20All%20School%20Year.pdf">“Queer
        all school year”</a> program and <a href="https://achieve.lausd.net/cms/lib/CA01000043/Centricity/domain/156/pdfs/lgbt%20conference%202021/LGBTQ%20Conference%20Program%20v7.pdf">"Standing
        with LGBTQ+ Students, Staff, and Families”</a> conference, as well as
      Rufo’s attacks on long-standing LAUSD policies.
    </p>
    <p>Rufo condemns LAUSD for advising teachers to “keep the student’s gender
      identity a secret from parents if the student so desires.” His criticism
      concerns <a href="https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/383/BUL-6224.2%20Transgender%20Policy%205%2013%2019.pdf">LAUSD’s
        instruction</a> “When school personnel find it important to discuss a
      student’s gender identity or expression with parents (if, for example, the
      student is being bullied based on their gender identity or expression),
      school personnel…shall take into consideration the safety, health and
      well-being of the student in deciding whether to disclose the student’s
      gender identity or expression to parents.”
    </p>
    <p>This isn’t educators keeping students’ gender identities “secret”—we’re
      respecting our students’ needs and privacy. By contrast, these critics are
      asking us to violate our students’ trust.
    </p>
    <p>Rufo criticizes <a href="https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/383/BUL-6224.2%20Transgender%20Policy%205%2013%2019.pdf">LAUSD’s
        instruction</a> that a “school shall accept the gender identity that
      each student asserts.” Max Eden of the American Enterprise Institute even
      proposes <a href="https://www.washingtonexaminer.com/restoring-america/community-family/time-for-childs-given-name-acts">"Child’s
        Given Name"</a> acts, which would prohibit school staff from “refer[ing]
      to a student by any name or pronoun other than those provided to the
      school by the child’s parents.”
    </p>
    <p>Each August teachers rewrite a few names on their seating charts because
      a student asks to be called by their middle name or nickname. Occasionally
      they ask that a different name and gender identity be used. It would never
      occur to most teachers to argue with the student or to call parents over
      these non-events. Doing so would destroy the student’s trust in us.</p>`
}