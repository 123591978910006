module.exports = {
id:121,
title: 'What We Learned at the UTLA Leadership Conference (Part 1)',
date: '2024-08-06',
content: `<p>Along with Monroe UTLA Chapter Chair Lourdes Quevedo and Vice Chair Jacqueline Fonseca, I attended the UTLA Leadership Conference in downtown Los Angeles this weekend. Below is some of what we learned.</p>
<h2>Cecily Myart-Cruz's Conference Opening Speech&ndash;the Tasks Ahead</h2>
<p>UTLA president Cecily Myart-Cruz opened the conference by laying out the current situation and what UTLA will be looking at between now and when our contract expires on June 30th 2025.</p>
<p>Our task now is to prepare to bargain. In the coming weeks school site UTLA chapter leaders are going to be holding meetings with members so that UTLA&rsquo;s rank-and-file can put forth their concerns and priorities so UTLA can incorporate them into our negotiating platform.<br /><br /></p>
<p>The platform will also be devised with input from <a href="https://reclaimourschoolsla.org/">Reclaim Our Schools LA</a>, a coalition of parents, families, students, educators, civil rights groups, school staff, and community organizations working to improve public education in Los Angeles, as well as from<a href="https://www.studentsdeserve.org/"> Students Deserve</a>.<br /><br /></p>
<p>UTLA hopes to have the platform done and voted on in October. Myart-Cruz says the organizational timeline is similar to what we had with the <a href="https://utla.net/app/uploads/2022/07/Beyond-Recovery-Platform_Full.pdf">Beyond Recovery Platform</a>, which proved to be an effective negotiating platform in 2022-2023.</p>
<h2>Weaknesses in 2019 Contract</h2>
<p>Myart-Cruz frankly acknowledged that there were some UTLA members who understandably felt that they did not get as much out of the 2019 contract as they should have, including counselors and itinerants, as well as EEC and SPED educators. Obviously in any union contract organization, the union won't win everything it wants, so its progress usually is not shared equally by all of its members. However, one purpose in expanding the UTLA bargaining contract bargaining team from 16 members in 2019 to 85 in 2023 was to try to minimize such unevenness.</p>
<h2>Progress in Battle with the Alliance Charter Network</h2>
<p><a href="https://www.laalliance.org">Alliance College-Ready Public Schools</a> operates over two dozen high schools and middle schools in the Los Angeles area. They bill themselves as &ldquo;the most prosperous charter school network.&rdquo;<br /><br /></p>
<p>Myart-Cruz explained how after five years of what she described as union busting, stalling, and cheap tricks, Alliance Charter finally recognized the UTLA-affiliated Alliance Educators United and is bargaining a union contract.<br /><br /></p>
<p>Alliance spent millions on union-busting efforts and ignored multiple legal orders from the state Public Employment Relations Board (PERB) to come to the bargaining table. Alliance appealed it, and last year a California appellate court ruled against them, ordering them to stop union-busting, recognize the union, and bargain. Alliance appealed to the California Supreme Court and lost again.</p>
<h2>What Alliance Educators United Wants</h2>
<p>Alliance Educators United's 30+ member expanded bargaining team is now negotiating with Alliance for job stability and enforceable class size and caseload caps.</p>
<p>Numerous times we&rsquo;ve detailed how non-union schools, very much including charters such as Alliance, are white-collar sweatshops for their teachers.</p>
<p>At nonunion schools teachers are often weighed down with taxing, unnecessary labor&ndash;yard duty before and after school, nutrition and lunch duty, chaperoning school functions and athletic events, and others. These duties reduce teachers&rsquo; ability to spend time helping students and preparing for classes.</p>
<p>Moreover, at nonunion schools teachers often must forgo their planning period to substitute for absent teachers. Excessive preps can also be a large drain on teachers&rsquo; time.</p>
<p>Overloading teachers with excessive demands on our time causes many teachers to leave the profession, and teacher turnover is a major problem.</p>
<h2>LGBTQ Students in Harm&rsquo;s Way</h2>
<p>In Cecily Myart-Cruz&rsquo;s opening statement, she also mentioned threats to LGBTQ youth.</p>
<p>The &ldquo;parents&rsquo; rights&rdquo; movement is pushing measures that put LGBTQ youth in harm's way. According to the <a href="https://www.lgbtmap.org/equality-maps">Movement Advancement Project</a>, eight states, all Republican-led, <a href="https://m.washingtontimes.com/news/2024/jul/2/gavin-newsom-decide-bill-banning-schools-telling-p/">have passed laws</a> mandating that schools notify parents of a student's gender transition. Even in liberal California, nearly a <a href="https://www.latimes.com/california/story/2023-09-21/transgender-students-parental-notification-policies-schools-lgbtq-forced-outing">dozen school districts now have policies</a> with some form of requirement that schools inform parents if a student appears to identify as transgender or gender-nonconforming.</p>
<p>Perhaps no district has received more criticism from conservative critics for its transgender policies than the Los Angeles Unified School District, particularly over its <a href="https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Domain/383/BUL-6224.2%20Transgender%20Policy%205%2013%2019.pdf">instruction</a> &ldquo;When school personnel find it important to discuss a student&rsquo;s gender identity or expression with parents (if, for example, the student is being bullied based on their gender identity or expression), school personnel&hellip;shall take into consideration the safety, health and well-being of the student in deciding whether to disclose the student&rsquo;s gender identity or expression to parents.&rdquo;</p>
<p>For rabble-rousing conservative activist Christopher Rufo, a key figure in the parents&rsquo; rights movement, this is LAUSD&rsquo;s &ldquo;radical gender-theory curriculum&rdquo; which advises teachers to &ldquo;keep the student&rsquo;s gender identity a secret from parents.&rdquo; But educators aren&rsquo;t keeping students&rsquo; gender identities &ldquo;secret&rdquo;&mdash;we&rsquo;re respecting our students&rsquo; needs and privacy.</p>
<p>Rufo also criticizes LAUSD&rsquo;s instruction that a &ldquo;school shall accept the gender identity that each student asserts.&rdquo; But if a student tells us their gender identity, who are we to argue or dispute it?</p>
<h2>CA AB 1955 Signed into Law to Protect LGBTQ youth</h2>
<p>AB 1955, signed into law by California Governor Gavin Newsom last month, will prohibit educational entities from &ldquo;enacting or enforcing any policy&hellip;that requires an employee&hellip;to disclose any information related to a pupil&rsquo;s sexual orientation, gender identity, or gender expression to any other person without the pupil&rsquo;s consent unless otherwise required by law.&rdquo; The new law also prohibits &ldquo;retaliation&hellip;against an employee on the basis that the employee supported a pupil&rdquo; in such situations.</p>
<p>While conservative critics often accuse educators of pushing pro-LGBTQ &ldquo;gender ideology&rdquo; on kids, in reality, the main motivation for educational institutions&rsquo; acceptance and welcoming of LGBTQ youth is not ideological or political. It is instead born of educators&rsquo; desire to minimize bullying and teen suicide.</p>
<p>The primary targets of bullying have long been LGBTQ youth, particularly gay male and trans youth. This is no frivolous concern&ndash;<a href="https://www.cbsnews.com/news/trevor-project-lgbtq-survey-mental-health-suicide/">one study found</a> that 12% of LGBTQ youth attempted suicide in 2023.</p>
<p>My students have often described their parents&rsquo; and families&rsquo; attitudes towards LGBTQ, and one thing is very clear&ndash;LGBTQ youth often have very good reasons for not wanting their gender identity or sexual orientation being disclosed to their families.</p>
<p>During the COVID school closures, one of my students was kicked out of her parents&rsquo; home, largely over her sexual orientation. She struggled to finish her senior year and graduate, while working long hours to pay her rent.</p>
<p>Another LGBTQ student wrote a powerful piece for Speech &amp; Debate called <em>Maricon</em>, in which he detailed how this word&mdash;akin to &ldquo;faggot&rdquo; in Spanish&mdash;was used cruelly by certain family members, and had haunted his youth.</p>
<p>A 2022 national survey conducted by the Trevor Project found that fewer than 1 in 3 young people who identify as transgender or nonbinary <a href="https://www.latimes.com/california/story/2023-09-21/transgender-students-parental-notification-policies-schools-lgbtq-forced-outing">report having gender-affirming homes</a>. </p>
<p>At school it is very important that students trust teachers and counselors. Is a transgender student who is being abused or is depressed or suicidal going to confide in a teacher or counselor if they feared it would result in them being outed to their families? If we did betray students in this manner, would we still be considered &ldquo;trusted adults&rdquo; that students should go to if they learn there's going to be a fight or if they see a weapon on campus?</p>
<p>We aren&rsquo;t conspiring with students to deceive parents; we're accepting our students as they are. AB 1955 offers needed protection to LGBTQ youth and the educators who help them.</p>
<p>To learn more, see <a href="https://www.citywatchla.com/lgbtq/29272-classroom-teachers-view-lausd-kids-need-ab-1955s-protections">CA AB 1955 Offers Needed Protection to LGBTQ Youth</a> (<em>CityWatch Los Angeles</em>, 7/25/24).</p>
<h2>Critics Misrepresent AB 1955</h2>
<p>Opponents of protections for LGBTQ youth, such as <a href="https://www.foxnews.com/us/california-bill-would-ban-schools-telling-parents-student-identifies-lgbtq-gut-and-amend">Fox News</a>, have been misrepresenting AB 1955, claiming that it prohibits schools from informing parents about their students' gender identity. For example, in <a href="https://www.dailynews.com/2024/08/05/union-controlledl-lawmakers-lie-to-parents-break-own-laws-hurt-special-needs-kids/">Union-controlled lawmakers lie to parents</a>, longtime teachers union critic Rebecca Friedrichs writes that AB 1955 &ldquo;prohibits anyone involved with public schools from giving any truthful information to parents asking about a child&rsquo;s transgender status.&rdquo;</p>
<p>No, AB 1955 prohibits employees from being mandated to share such information.</p>`
}